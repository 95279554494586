import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { AuthorizationInputArgs } from "./work-order-line-authorization-repair-item.model";

@dataSource()
export class WorkOrderLineAuthorizationSummary {
    @keyColumn() id: string;
    @column() workOrderLineId: string;
    @column() approvedAmount: number;
    @column() outOfPocketAmount: number;
    @column() fullArgs: string;
    @column() createdDate: Date;
    @column() superceededById: string;
    @column() repairTypeId: string;
    @column() repairTypeName: string;
    @column() repairTypeDescription: string;
    @column() number: number;
    @column() workOrderId: string;
    @column() createdById: string;
    @column() createdByName: string;

    private _args: AuthorizationInputArgs;
    get args() {
        if (!this._args && this.fullArgs) {
            try {
                this._args = JSON.parse(this.fullArgs);
            } catch { }
        }

        return this._args;
    }
}