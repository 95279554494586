import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceCustomerPropertyService {
    @keyColumn() id: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() maintenanceServiceOfferingId: string;
    @column() price: number;
    @column() cost: number;
    @column() contractorId: string;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() cancellationDate: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() preferredDay: number;
    @column() preferredWeek: number;
    @column() maintenanceServicePricingId: string;
    @column() startDate: Date;
    @column() pricePerMonth: number;
    @column() visitsPerYear: number;
    @column() cancellationReasonId: string;
    @column() isOneTime: boolean;
    @column() costPerMonth: number;
    @column() initialMonthPrice: number;
    @column() promotionCodeId: string;
    @column() source: string;
    @column() cancellationFee?: number;
    @column() cancellationFeeMonthsInEffect?: number;
    @column() cancellationNote: string;
    @column() maintenanceServiceContractLanguageId: string;
    @column() marketingSourceId: string;
    @column() pauseDate: Date;
    @column() unPauseDate: Date;
}