<form>
    <mat-form-field appearance="outline">
        <mat-label>{{placeholderLabel}}</mat-label>
      <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
      <mat-autocomplete class="multi-line" autoActiveFirstOption #auto="matAutocomplete"  [displayWith]="displayFn"  (optionSelected)="selected($event)">
        <mat-option *ngFor="let searchItem of allSearchItems" [value]="searchItem">
          {{searchItem.name}}
          <div class="small">
          {{searchItem.email}}</div>
          <div class="small" *ngIf="searchItem.homeNumber || searchItem.mobileNumber">
            <span *ngIf="searchItem.homeNumber">Home: {{searchItem.homeNumber}}</span>  <span *ngIf="searchItem.mobileNumber"> Mobile: {{searchItem.mobileNumber}}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
