import { Injectable } from '@angular/core';
import { ApiService, ResultExtractionType } from '@cogent/client/api';
import { AuthService } from '@cogent/client/auth';
import { Payment, StripeCustomerModel, StripePayoutSummary, StripeTransactionCharge } from '@upkeeplabs/models/cogent';
import { StripeCard } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class StripeApiService {
    constructor(private api: ApiService,
        private authService: AuthService) { }

    getUnexportedPayouts(){
        return this.api.getArrayNode("StripePayoutSummary", {exportDate_eq: '{{null}}', orderby:'ArrivalDate'},() => new StripePayoutSummary());
    }

    saveCustomerCreditCard(customerId: string, stripeToken: string): Promise<any> {
        return this.api.patchSingleDotNet('Entity/' + customerId + '/AddCardFromToken/' + stripeToken, null);
    }

    async addTempCardFromToken(stripeToken: string): Promise<any> {
        const entityId = this.authService.parsedJwt.user_id;
        return this.api.patchSingleDotNet(`Entity/AddTempCardFromToken/${stripeToken}/${entityId}`, null);
    }

    getStripeCustomer(customerId: string): Promise<StripeCustomerModel> {
        return this.api.getSingleDotNet('Entity/' + customerId + '/StripeCustomer', null, () => new StripeCustomerModel());
    }

    getCustomerStripeCards(customerId: string): Promise<StripeCard[]> {
        return this.api.getArrayDotNet('Entity/' + customerId + '/StripeCards', null, () => new StripeCard());
    }

    getStripeCardsFromUrl(url: string): Promise<StripeCard[]> {
        return this.api.getArrayDotNet(url, null, () => new StripeCard());
    }

    deleteStripeCard(customerId: string, cardId: string): Promise<any> {
        return this.api.deleteDotNet('Entity/StripeCards/' + customerId + '/' + cardId, null, ResultExtractionType.Single);
    }

    nodeDeleteStripeCard(customerId: string, cardId: string, newDefaultCardId: string = null) {
        return this.api.deleteNode(`stripe/${customerId}/${cardId}/${newDefaultCardId ? newDefaultCardId : ''}`, null, ResultExtractionType.Single)
    }

    // Deprecated.  Use createCharge2
    createCharge(customerId: string, charge: StripeTransactionCharge) {
        return this.api.postSingleDotNet<Payment>('Entity/' + customerId + '/StripeCards/Charge', charge, null, () => new Payment());
    }

    createCharge2(customerId: string, charge: StripeTransactionCharge, errorHandler) {
        return this.api.postSingleDotNet<Payment>(`Entity/${customerId}/stripe-cards/charge`, charge, null, () => new Payment(), false, errorHandler);
    }

    createChargeFullReturn(customerId: string, charge: StripeTransactionCharge, errorHandler) {
        return this.api.postSingleDotNet<any>(`Entity/${customerId}/stripe-cards/charge-full-return`, charge, null, () => new Payment(), false, errorHandler);
    }

    createChargeFullReturnV2(charge: StripeTransactionCharge) {
        return this.api.postSingleNode(`stripe/process-charge`,charge);
    }

    getStripeTransactionCharges(startDate: Date, endDate: Date) {
        return this.api.getSingleDotNet('Stripe/charge/query', { startDate, endDate });
    }

    getStripeRefunds(startDate: Date, endDate: Date) {
        return this.api.getSingleDotNet('Stripe/refund/query', { startDate, endDate });
    }
}
