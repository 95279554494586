import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
import { Note } from '@upkeeplabs/models/cogent';

export class NoteClient extends Note {
    attachments: NoteAttachment[];
    subject: string;
    noteWasHandled: boolean;
    workOrderNumber: number;

    constructor(subject = '', createdById = '', createdDate: Date = null, noteText = '', policyId = '', workOrderId = '', creatorName = '', id: string = null) {
        super();
        this.attachments = [];
        this.subject = subject;
        this.createdById = createdById;
        this.createdDate = createdDate;
        this.noteText = noteText;
        this.policyId = policyId;
        this.workOrderId = workOrderId;
        this.creatorName = creatorName;
        this.id = id;
    }

    get createdByUrl() {
        if (!this.createdById) {
            return null;
        }

        return `${ApiEndpoints.endPointDotNet}entity/photo/${this.createdById}`;
    }
}


export class NoteAttachment {
    noteId: string;
    constructor(name = '', url = '') { }
    name: string;
    base64: string;
}

export class GroupedNote {
    group: string;
    notes: Note[];
    expanded = true;
    collapsing = false;
    expanding = false;

    private static datesEqual(date1: Date, date2: Date) {
        return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
            && date1.getFullYear() === date2.getFullYear();
    }

    private static getGroup(note: Note): string {
        if (!note.createdDate) {
            return 'Unknown';
        }
        if (note.pinned) {
            return 'Pinned';
        }

        let dateToCheck = new Date();

        if (this.datesEqual(note.createdDate, dateToCheck)) {
            return 'Today';
        }

        dateToCheck.setDate(dateToCheck.getDate() - 1);

        if (this.datesEqual(note.createdDate, dateToCheck)) {
            return 'Yesterday';
        }

        const week = new Date(dateToCheck);
        while (week.getDay() > 0) {
            week.setDate(week.getDate() - 1);
        }

        if (note.createdDate >= week) {
            return 'This week';
        }

        week.setDate(week.getDate() - 7);
        if (note.createdDate >= week) {
            return 'Last week';
        }

        if (note.createdDate.getMonth() === new Date().getMonth() && note.createdDate.getFullYear() === new Date().getFullYear()) {
            return 'This Month';
        }

        dateToCheck = new Date();
        dateToCheck.setMonth(new Date().getMonth() - 1);

        if (note.createdDate.getMonth() === dateToCheck.getMonth() && note.createdDate.getFullYear() === new Date().getFullYear()) {
            return 'Last Month';
        }

        if (note.createdDate.getFullYear() === new Date().getFullYear()) {
            return 'This Year';
        }

        dateToCheck = new Date();
        dateToCheck.setFullYear(dateToCheck.getFullYear() - 1);
        if (note.createdDate.getFullYear() === dateToCheck.getFullYear()) {
            return 'Last Year';
        }

        return 'Older';

    }

    public static fromNotes(notes: Note[]): GroupedNote[] {
        const groupedNotes: GroupedNote[] = [];

        if (notes) {
            notes.forEach(note => {
                const group = GroupedNote.getGroup(note);

                let groupedNote = groupedNotes.filter(i => i.group === group)[0];
                if (!groupedNote) {
                    groupedNote = new GroupedNote();
                    groupedNote.group = group;
                    groupedNote.notes = [];
                    groupedNote.expanded = true;

                    groupedNotes.push(groupedNote);
                }


                groupedNote.notes.push(note);
            });
        } 
        return groupedNotes;
    }

    toggleGroup() {
        this.expanding = false;
        this.collapsing = false;



        if (this.expanded) {
            this.collapsing = true;
            setTimeout(() => { this.expanded = false; this.collapsing = false; }, 300);
        } else {
            this.expanding = true;
            this.expanded = true;
            setTimeout(() => { this.expanded = true; this.expanding = false; }, 300);
        }
    }
}
