import { keyColumn, column, dataSource } from "@upkeeplabs/linq";


/**
 * @deprecated this class is for saving to the database only
 */
@dataSource()
export class AuthorizationRepairItem {
    @keyColumn() id: string;
    @column() workOrderItemId: string;
    @column() name: string;
    @column() priceRangeMin: number;
    @column() priceRangeMax: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    @column() companyNeverProvides: boolean;
    @column() allowQuantityEntry: boolean;
    @column() attributesDefinition: string;
    @column() grossMarginAdjustmentPct: number;
    @column() coveredType: string;
    @column() authorizationRepairItemGroupId: string;
    @column() aliases: string;
    @column() companyPrefersToProvide: boolean;
    @column() priceRangeDeltaPct: number;
    @column() workOrderItemTypes: string;
    @column() restrictByWorkOrderItemTypes: boolean;
    @column() workOrderItemIds: string;
    //@column() dummyColumn: string;

    private _aliasList: string[];

    get aliasList(): string[] {
        if (!this._aliasList) {
            if (this.aliases) {
                try {
                    this._aliasList = JSON.parse(this.aliases);
                } catch {
                    this._aliasList = [];
                }
            } else {
                this._aliasList = [];
            }
        }

        return this._aliasList;
    }
}