import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

export class RouteDefinitions {
    static getAllRouteDefinitions() {
        return [
            {
                path: 'home',
                data: { title: 'Home', iconClass: 'dashboard', description: 'Home Page' },
                expanded: false,
                children: [
                    {
                        path: 'history',
                        data: {
                            title: 'Recent Items',
                            allowQuickAccess: true,
                            iconClass: 'view_module',
                            description: 'View and search your recent items',
                            parentKey: 'user'
                        }
                    },
                    {
                        path: 'task-queues',
                        data: {
                            title: 'Task Queues',
                            allowQuickAccess: true,
                            iconClass: 'view_agenda',
                            description: 'Task Queues',
                            parentKey: 'home',
                        }
                    },

                    {
                        path: 'task-queues-board',
                        data: {
                            title: 'Task Boards',
                            iconClass: 'view_week',
                            description: 'Task Boards'
                        },
                    },

                    {
                        path: 'custom-table-queues',
                        data: {
                            title: 'Custom Table Queues',
                            iconClass: 'view_agenda',
                            description: 'Custom Table Queues'
                        },
                    },
                    {
                        path: 'custom-table-boards',
                        data: {
                            title: 'Custom Table Boards',
                            iconClass: 'view_week',
                            description: 'Custom Table Boards',
                            allowQuickAccess: true,
                        },
                    },
                    {
                        path: 'my-tasks',
                        data: {
                            title: 'My Tasks',
                            allowQuickAccess: true,
                            iconClass: 'playlist_add_check',
                            description: 'My Tasks',
                            parentKey: 'home',
                        }
                    },
                    {
                        path: 'contact-search',
                        data: {
                            title: 'Contact Search',
                            iconClass: 'voicemail',
                            description: 'Search for previous phone calls/chats',
                        }
                    },
                    {
                        path: 'cogent-search',
                        data: {
                            title: 'Cogent Search',
                            iconClass: 'travel_explore',
                            description: 'Search for item in Cogent',
                        }
                    },
                    {
                        path: 'create-communication-batch',
                        data: {
                            title: 'Create Communication Batch',
                            allowQuickAccess: true,
                            iconClass: 'drafts'
                        },
                    },

                    {
                        path: 'communication-batch-status',
                        data: {
                            title: 'Communication Batch Status',
                            allowQuickAccess: true,
                            iconClass: 'podcasts'
                        },
                    },
                ]
            },
            {
                path: 'sales',
                expanded: false,
                data: {
                    title: 'Sales Home',
                    allowQuickAccess: true,
                    iconClass: 'attach_money',
                    description: 'Sales home page'
                },
                children: [
                    {
                        path: 'subscription-report',
                        data: {
                            title: 'Subscription Report',
                            iconClass: 'assessment',
                            allowQuickAccess: true,
                            description: 'View the subscription report',
                            parentKey: 'sales'
                        }
                    },
                    {
                        path: 'convert-to-buyers-coverage',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Convert to Buyer\'s Coverage',
                            iconClass: 'create_new_folder',
                            description: 'Covert sellers coverage to buyers coverage',
                            parentKey: 'sales',
                        }
                    },
                    {
                        path: 'new-subscription',
                        data: {
                            title: 'New subscription',
                            allowQuickAccess: true,
                            iconClass: 'create_new_folder',
                            description: 'Start the wizard to create a new subscription',
                            parentKey: 'sales',
                        }
                    },
                    {
                        path: 'merge-subscriptions',
                        data: {
                            title: 'Merge Subscriptions',
                            allowQuickAccess: true,
                            iconClass: 'compress',
                            description: 'Merge two subscriptions into 1',
                        },
                    },
                    {
                        path: 'print-renewals',
                        data: {
                            title: 'Print Renewals',
                            allowQuickAccess: true,
                            iconClass: 'print',
                            description: 'Print the renewal letters for expiring subscriptions',
                        },
                    },
                    {
                        path: 'print-renewal-cards',
                        data: {
                            title: 'Print Renewal Cards',
                            allowQuickAccess: true,
                            iconClass: 'print',
                            description: 'Print the renewal cards for expiring subscriptions',
                        },
                    },
                    {
                        path: 'print-thank-you-post-cards',
                        data: {
                            title: 'Print Thank You Post Cards',
                            allowQuickAccess: true,
                            iconClass: 'drafts',
                            description: 'Print the referring partner thank you post cards',
                        },
                    },
                    {
                        path: 'service-performed-you-post-cards',
                        data: {
                            title: 'Print Service Performed Post Cards',
                            allowQuickAccess: true,
                            iconClass: 'build',
                            description: 'Print the referring partner service performed post cards',
                        },
                    },

                    {
                        path: 'merge-entities',
                        data: {
                            title: 'Merge Entities',
                            allowQuickAccess: true,
                            iconClass: 'call_merge',
                            description: 'Merge two entity records into 1',
                        },
                    },
                    {
                        path: 'top-sales-report',
                        data: {
                            title: 'Top Orders Report',
                            allowQuickAccess: true,
                            iconClass: 'format_list_numbered',
                            description: 'Display the top order producers',
                        }
                    },
                    {
                        path: 'subscription-queues',
                        data: {
                            title: 'Subscription Queues',
                            allowQuickAccess: true,
                            parentKey: 'sales',
                            description: 'Subscription Queues',
                            iconClass: 'view_agenda'
                        }
                    },
                    {
                        path: 'queues-board',
                        data: {
                            title: 'Subscription Boards',
                            iconClass: 'view_week',
                            description: 'Subscription Boards'
                        },
                    },
                    {
                        path: 'sales-employee-target-entry',
                        data: {
                            title: 'Rep Sales Target Entry',
                            allowQuickAccess: true,
                            parentKey: 'sales',
                            description: 'Enter Rep Sales Targets',
                            iconClass: 'data_usage'
                        }
                    },
                    {
                        path: 'sales-target-entry',
                        data: {
                            title: 'Sales Target Entry',
                            allowQuickAccess: true,
                            parentKey: 'sales',
                            description: 'Enter Sales Targets',
                            iconClass: 'data_usage'
                        }
                    },
                    {
                        path: 'sales-report/orders',
                        data: {
                            title: 'Orders Report',
                            iconClass: 'dynamic_feed',
                            description: 'Orders Report',
                            parentKey: 'sales',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'sales-commission-report',
                        data: {
                            title: 'Commission Report',
                            iconClass: 'dynamic_feed',
                            description: 'Commission Report',
                            parentKey: 'sales',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'sales-report/closing',
                        data: {
                            title: 'Closing Report',
                            iconClass: 'how_to_vote',
                            description: 'Closing Report',
                            parentKey: 'sales',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'sales-commissionable-report',
                        data: {
                            title: 'Orders By Rep',
                            iconClass: 'recent_actors',
                            description: 'Orders By Rep',
                            parentKey: 'sales',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'contract-batch-print',
                        data: {
                            title: 'Contract Batch Print',
                            allowQuickAccess: true,
                            parentKey: 'sales',
                            description: 'Contract Batch Print',
                            iconClass: 'print',
                        },
                    },
                    {
                        path: 'account-executive-lookup',
                        data: {
                            title: 'Account Executive Lookup',
                            allowQuickAccess: true,
                            iconClass: 'account_circle',
                            description: 'Provides account executive lookup by zip code',
                        }
                    },
                    {
                        path: 'renewal-goal-entry',
                        data: {
                            title: 'Renewal Goal Entry',
                            allowQuickAccess: true,
                            iconClass: 'insights',
                            description: 'Add and edit renewal goal percentages',
                        },
                    },
                    {
                        path: 'favor-fund-weight-entry',
                        data: {
                            title: 'Favor Fund Weight Entry',
                            allowQuickAccess: true,
                            iconClass: 'price_check',
                            description: 'Add and edit favor fund weights',
                        },
                    },
                    {
                        path: 'favor-fund-budget-overview',
                        data: {
                            title: 'Favor Fund Budgets',
                            allowQuickAccess: true,
                            iconClass: 'price_change',
                            description: 'Add and edit favor fund budgets',
                        },
                    },
                    {
                        path: 'favor-fund-detail',
                        data: {
                            title: 'Favor Fund Detail',
                            allowQuickAccess: true,
                            iconClass: 'savings',
                            description: 'View Favor Fund Detail',
                        },
                    },
                    {
                        path: 'scheduled-maintenance-order',
                        data: {
                            title: 'Scheduled Maintenance Order',
                            iconClass: 'pool',
                            description: 'Wizard for ordering maintenance services',
                            parentKey: 'sales',
                            allowQuickAccess: true,
                        },
                    },
                    {
                        path: 'quote-queues',
                        data: {
                            title: 'Quote Queues',
                            description: 'Quote Queues',
                            iconClass: 'view_agenda',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'quote-board',
                        data: {
                            title: 'Quote Boards',
                            description: 'Quote Boards',
                            iconClass: 'view_week',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'configure-quote-landing',
                        data: {
                            title: 'Configure Quote Landing Page',
                            allowQuickAccess: true,
                            iconClass: 'request_quote',
                            description: 'Change the quote landing page messaging and images',
                        }
                    },
                ]
            },
            {
                path: 'entity',
                hidden: true,
                data: {},
                children: [
                    {
                        path: 'add/Contractor',
                        data: {
                            allowQuickAccess: true,
                            iconClass: 'add',
                            title: 'Add Service Pro',
                            description: 'Add Service Pro',
                        }
                    },
                    {
                        path: 'add-real-estate-company',
                        data: {
                            title: 'Add Real Estate Partner',
                            iconClass: 'add_to_queue',
                            description: 'Add Real Estate Partner',
                            allowQuickAccess: true,
                        }
                    }
                ]
            },
            {
                path: 'service-pro-relations',
                hidden: true,
                data: {
                    title: 'Service Pro Information',
                    description: 'Service Pro Information',
                    iconClass: 'local_delivery',
                },
                children: [],
            },
            {
                path: 'purchasing',
                hidden: true,
                children: [
                    {
                        path: 'purchase-order',
                        data: {
                            title: 'Purchase Orders',
                            description: 'Purchase Orders',
                        }
                    },
                    {
                        path: 'purchase-order-queues/:id',
                        data: {
                            title: 'Purchase Order Queues',
                            description: 'Purchase Order Queues',
                            iconClass: 'view_agenda',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'purchase-order-queues-board/:id',
                        data: {
                            title: 'Purchase Order Boards',
                            description: 'Purchase Order Boards',
                            iconClass: 'view_week',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'purchase-order-item-edit',
                        data: {
                            title: 'Item Edit',
                            description: 'Edit purchase order item master list',
                            iconClass: 'inventory',
                            allowQuickAccess: true,
                        }
                    },

                    {
                        path: 'return-parts/:id',
                        data: {
                            title: 'Parts Return',
                            description: 'Return parts to supplier',
                            iconClass: 'turn_left'
                        }
                    },
                    {
                        path: 'find-part',
                        data: {
                            title: 'Find Part',
                            description: 'Search for an ordered part',
                            iconClass: 'pageview'
                        }
                    },
                    {
                        path: 'inventory',
                        data: {
                            title: 'Parts Inventory',
                            description: 'Manament for parts held in inventory',
                            iconClass: 'inventory',
                            allowQuickAccess: true,
                        }
                    }
                ]
            },

            {
                path: 'users',
                hidden: true,
                data: {},
                children: [
                    {
                        path: 'department-home-page/sales',
                        data: {
                            description: 'Sales Home Page',
                            title: 'Sales Home Page',
                            iconClass: 'home',
                        }
                    },
                    {
                        path: 'department-home-page/service',
                        data: {
                            description: 'Care Home Page',
                            title: 'Care Home Page',
                            iconClass: 'home',
                        }
                    },
                    {
                        path: 'department-home-page/accounting',
                        data: {
                            description: 'Accounting Home Page',
                            title: 'Accounting Home Page',
                            iconClass: 'home',
                        }
                    },

                    {
                        path: 'mentions',
                        data: {
                            title: 'Mentions',
                            parentKey: 'user',
                            allowQuickAccess: true,
                            iconClass: 'record_voice_over'
                        }
                    },
                    {
                        path: 'department-home-page/authorizations',
                        data: {
                            description: 'Authorizations Home Page',
                            title: 'Authorizations Home Page',
                            iconClass: 'home',
                        }
                    },
                    {
                        path: 'department-home-page/contractor-relations',
                        data: {
                            description: 'Service Pro Relations Home Page',
                            title: 'Service Pro Relations Home Page',
                            iconClass: 'home',
                        }
                    },
                    {
                        path: 'task-queues',
                        data: {
                            description: 'Task Queues',
                            title: 'Task Queues',
                            iconClass: 'view_agenda',
                        }
                    },
                    {
                        path: 'custom-table-queues',
                        data: {
                            description: 'Custom Table Queues',
                            title: 'Custom Table Queues',
                            iconClass: 'view_agenda',
                        }
                    },
                    {
                        path: 'contact-search',
                        data: {
                            title: 'Contact Search',
                            iconClass: 'voicemail',
                            description: 'Search for previous phone calls/chats'
                        },
                    },
                    {
                        path: 'user-defined-table-maint',
                        data: {
                            title: 'User Defined Table Maintenace',
                            iconClass: 'table_view',
                            parentKey: 'user',

                        },
                    },
                ]
            },
            {
                path: 'service',
                expanded: false,
                data: {
                    title: 'Care Home',
                    allowQuickAccess: true,
                    iconClass: 'kitchen',
                    description: 'Customer Care Home Page'
                },
                children: [
                    {
                        path: 'reports/service-request-report',
                        data: {
                            title: 'Service Request Report',
                            description: 'A report showing a history of service requests',
                            iconClass: 'insert_drive_file',
                            allowQuickAccess: true,
                            parentKey: 'care'
                        }
                    },

                    {
                        path: 'find-subscription',
                        data: {
                            title: 'Start A Service Request',
                            iconClass: 'build',
                            description: 'Find a subscription and begin a service request',
                            allowQuickAccess: true,
                        }
                    },

                    {
                        path: 'request-service',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Request Service',
                            iconClass: 'build',
                            description: 'Start the claim wizard'
                        },
                    },

                    {
                        path: 'edit-login',
                        data: {
                            title: 'Edit Login',
                            iconClass: 'account_box',
                            description: 'Edit Login'
                        },
                    },
                    {
                        path: 'service-queues',
                        data: {
                            title: 'Service Queues',
                            description: 'Service Queues',
                            iconClass: 'view_list',
                            parentKey: 'care',
                            allowQuickAccess: true
                        }
                    },
                    {
                        path: 'cash-out',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Create Cash Outs',
                            description: 'Create Cash Outs',
                            parentKey: 'care',
                        }
                    },
                    {
                        path: 'service-queues-board',
                        data: {
                            title: 'Service Boards',
                            description: 'Service Boards',
                            iconClass: 'view_week',
                            parentKey: 'care',
                            allowQuickAccess: true
                        },
                    },
                    {
                        path: 'after-call-survey-report',
                        data: {
                            title: 'After Call Survey Report',
                            description: 'View after call survey report data',
                            iconClass: 'sentiment_neutral',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'claim-detail',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'View Job Detail',
                            description: 'View Job Detail',
                        }
                    },
                    {
                        path: 'stick-note-search',
                        data: { title: 'Sticky Note Search', description: 'Sticky Note Search', iconClass: 'sticky_note_2', allowQuickAccess: true }
                    },
                    {
                        path: 'recall',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Recall'
                        }
                    },
                    {
                        path: 'maintenance-service-queue-board',
                        data: {
                            title: 'HMS Boards',
                            iconClass: 'view_week',
                            description: 'HMS Boards'
                        },
                    },
                    {
                        path: 'maintenance-service-queues',
                        data: {
                            title: 'HMS Queues',
                            iconClass: 'view_agenda',
                            description: 'HMS Queues',
                            allowQuickAccess: true,
                        },
                    },
                    {
                        path: 'reports/closed-requests-report',
                        data: {
                            title: 'Closed Requests Report',
                            description: 'A report showing closed service requests by agent',
                            iconClass: 'support_agent',
                            allowQuickAccess: true,
                            parentKey: 'care'
                        }
                    },
                    {
                        path: 'wall-board',
                        data: {
                            title: 'Wall Board',
                            description: 'Wall Board',
                            iconClass: 'desktop_windows',
                            allowQuickAccess: true,
                            parentKey: 'care',
                        }
                    }
                    // {
                    //     path: 'service-pro-job-history',
                    //     data: {
                    //         title: 'Job History',
                    //         iconClass: 'library_books',
                    //         description: 'View Service Pros Job History'
                    //     }
                    // },
                ]
            },

            {
                path: 'subscription',
                expanded: false,
                hidden: true,
                data: {

                },
                children: [

                    {
                        path: 'search',
                        data: {
                            title: 'New Service Request',
                            allowQuickAccess: true,
                            iconClass: 'build',
                            description: 'New Service Request',
                            parentKey: 'sales'
                        }
                    },
                    {
                        path: 'edit-login',
                        data: {
                            title: 'Edit Login',
                            iconClass: 'account_box',
                            description: 'Edit Login'
                        },
                    },

                    {
                        path: 'maint-service-customer-property/:id',
                        data: {
                            title: 'Customer Property View',
                            iconClass: 'cleaning_services',
                            description: 'Maintenance service customer property viewer',
                        }
                    },
                ]
            },



            {
                path: 'authorizations',
                expanded: false,
                data: {
                    title: 'Authorizations Home',
                    description: 'Home page for the authorizations department',
                    iconClass: 'gavel',
                    allowQuickAccess: true
                },
                children: [
                    {
                        path: 'create-authorization',
                        hidden: true,
                        data: {
                            title: 'Create Authorization',
                            description: 'Create Authorization',
                            iconClass: 'gavel',
                            parentKey: 'autho',
                        }
                    },
                    {
                        path: 'purchase-order-queues',
                        redirectTo: '/purchasing/purchase-order-queues',
                        data: {
                            title: 'Purchase Order Queues',
                            description: 'Purchase Order Queues',
                            iconClass: 'view_agenda',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'purchase-order-boards',
                        redirectTo: '/purchasing/purchase-order-queue-boards',
                        data: {
                            title: 'Purchase Order Boards',
                            description: 'Purchase Order Boards',
                            iconClass: 'view_week',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: '/find-part',
                        data: {
                            title: 'Find Part',
                            description: 'Search for an ordered part',
                            iconClass: 'pageview'
                        }
                    },
                    {
                        path: 'batch-order-parts',
                        data: {
                            title: 'Batch Order Parts',
                            description: 'Screen to allow you to batch order parts in queue',
                            allowQuickAccess: true,
                            iconClass: 'kitchen'
                        },
                    },
                    {
                        path: 'inventory',
                        data: {
                            title: 'Parts Inventory',
                            description: 'Manament for parts held in inventory',
                            iconClass: 'inventory',
                            allowQuickAccess: true,
                        }
                    },

                    {
                        path: 'autho-studio',
                        data: {
                            title: 'Autho Studio',
                            description: 'Setup and maintain job cost authorizations',
                            iconClass: 'balance',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'wall-board',
                        data: {
                            title: 'Authorization Wall Board',
                            description: 'Wall board to display authorizations wall board metrics',
                            iconClass: 'desktop_windows',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'edit-brand',
                        data: {
                            title: 'Edit Brands',
                            description: 'Edit the appliance brands',
                            iconClass: 'label',
                            allowQuickAccess: true,
                            parentKey: 'autho',
                        }
                    },
                ]
            },

            {
                path: 'service-pro-relations',
                expanded: false,
                data: {
                    title: 'Service Pro Relations Home',
                    allowQuickAccess: true,
                    iconClass: 'local_shipping',
                    parentKey: 'contractor-relations',
                    description: 'Service Pro Relations Home Page',
                },
                children: [
                    {
                        path: 'reports/contractor-summary-report',
                        data: {
                            title: 'Service Pros Report',
                            description: 'Show all service pros',
                            iconClass: 'person',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations',
                        }
                    },

                    {
                        path: 'reports/vendor-summary-report',
                        data: {
                            title: 'Vendor Summary',
                            description: 'A report with all the vendors',
                            iconClass: 'shopping_cart',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'reports/contractor-stats',
                        data: {
                            title: 'Service Pro Report Cards',
                            description: 'Show service pro report cards',
                            iconClass: 'person',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations'
                        }
                    },
                    {
                        path: 'reports/service-pro-status-report',
                        data: {
                            title: 'Service Pro Status Report',
                            description: 'Show service pro status report',
                            iconClass: 'person',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations'
                        }
                    },

                    {
                        path: 'enter-targets',
                        data: {
                            title: 'Enter Service Pro Targets',
                            description: 'Enter Service Pro Targets',
                            iconClass: 'wifi_tethering',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations'
                        }
                    },
                    {
                        path: 'enter-commitments',
                        data: {
                            title: 'Enter Service Pro Commitments',
                            description: 'Enter Service Pro Commitments',
                            iconClass: 'wifi_tethering',
                            allowQuickAccess: true,
                            parentKey: 'contractor-relations'
                        }
                    },
                    {
                        path: 'entity-queues',
                        data: {
                            title: 'Entity Queues',
                            description: 'Entity Queues',
                            iconClass: 'view_agenda',
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'entity-boards',
                        data: {
                            title: 'Entity Boards',
                            description: 'Entity Boards',
                            iconClass: 'view_week',
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'service-pro-surveys',
                        data: {
                            title: 'Service Pro Surveys',
                            description: 'Survey report',
                            iconClass: 'sentiment_neutral',
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'dispatch-summary',
                        data: {
                            title: 'Dispatch Summary',
                            description: 'Dispatch Summary',
                            allowQuickAccess: true,
                            iconClass: 'list_alt',
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'dispatch-initialize',
                        data: {
                            title: 'Dispatch Setup',
                            description: 'Dispatch Setup',
                            iconClass: 'insert_link',
                            parentKey: 'contractor-relations',
                        }
                    },
                    {
                        path: 'document-audit',
                        data: {
                            title: 'Document Audit',
                            description: 'Audit screen for service pro documents',
                            iconClass: 'document_scanner',
                            parentKey: 'contractor-relations',
                        }
                    },
                ]
            },

            {
                path: 'accounting',
                expanded: false,
                data: {
                    title: 'Accounting Home',
                    allowQuickAccess: true,
                    iconClass: 'collections_bookmark',
                    description: 'Accounting Home'
                },
                children: [
                    {
                        path: 'deferred-revenue',
                        data: {
                            title: 'Deferred Revenue Report',
                            iconClass: 'description',
                            parentKey: 'accounting',
                            description: 'View the deferred Revenue Report',
                        }
                    },
                    {
                        path: 'export-service-pro-invoices',
                        data: {
                            title: 'Export Service Pro Invoices',
                            iconClass: 'description',
                            parentKey: 'accounting',
                        }
                    },
                    {
                        path: 'export-purchase-orders',
                        data: {
                            title: 'Export Purchase Orders',
                            description: 'Export Purchase Orders',
                            iconClass: 'assignment_returned',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'export-credits',
                        redirectTo: 'accounting/export-payments/Credit',
                        data: {
                            title: 'Export Credits',
                            iconClass: 'account_balance',
                            parentKey: 'accounting',
                        }
                    },
                    {
                        path: 'export-payments',
                        data: {
                            hidden: true,
                        }
                    },
                    {
                        path: 'export-cash-outs',
                        data: {
                            title: 'Export Cash Outs',
                            allowQuickAccess: true,
                            parentKey: 'accounting',
                            iconClass: 'reply'
                        }
                    },
                    {
                        path: 'approve-service-pro-invoices',
                        data: {
                            title: 'Approve Service Pro Invoices',
                            allowQuickAccess: true,
                            parentKey: 'accounting',
                            iconClass: 'check_circle_outline',

                        }
                    },
                    {
                        path: 'export-checks',
                        data: {
                            title: 'Export Checks',
                            iconClass: 'subtitles',
                            parentKey: 'accounting',
                            description: 'Export check payments to QuickBooks',
                        }
                    },
                    {
                        path: 'export-credit-cards',
                        data: {
                            title: 'Export Credit Cards',
                            iconClass: 'credit_card',
                            parentKey: 'accounting',
                            description: 'Export credit card payments to QuickBooks',
                        }
                    },
                    {
                        path: 'export-promotion-codes',
                        data: {
                            title: 'Export Promotion Codes',
                            iconClass: 'credit_card',
                            parentKey: 'accounting',
                            description: 'Export promotion code payments to QuickBooks',
                        }
                    },
                    {
                        path: 'check-entry',
                        data: {
                            title: 'Check Entry',
                            iconClass: 'account_balance_wallet',
                            parentKey: 'accounting',
                            description: 'Batch entry of check payments',
                            allowQuickAccess: true,
                        }
                    },
                    {
                        path: 'purchase-order-reconcile',
                        data: {
                            title: 'Purchase Order Reconcile',
                            iconClass: 'account_balance_wallet',
                            parentKey: 'accounting',
                            description: 'Reconcile Purchase Orders with Credit Card',
                        }
                    },
                    {
                        path: 'manual-credit-card-entry',
                        data: {
                            title: 'Credit Card Entry',
                            iconClass: 'card_travel',
                            parentKey: 'accounting',
                        },

                    },
                    {
                        path: 'service-pro-invoice-queues',
                        data: {
                            title: 'Service Pro Invoice Queues',
                            iconClass: 'view_agenda',
                            description: 'View Service Pro Invoice Queues',
                        },
                    },
                    {
                        path: 'batch-service-pro-invoicing',
                        data: {
                            title: 'Batch Service Pro Invoice Entry',
                            iconClass: 'toc',
                            description: 'Batch Entry'
                        },
                    },
                    {
                        path: 'service-pro-payment-summary-report',
                        data: {
                            title: 'Payment Summary Report',
                            allowQuickAccess: true,
                            iconClass: 'payments',
                            description: 'Shows totals paid to service pro based on export date'
                        }
                    },

                    {
                        path: 'payment-file-import',
                        data: {
                            title: 'Payment File Importer',
                            iconClass: 'publish',
                            description: 'Import payment records from CSV file',
                            allowQuickAccess: true,
                        },
                    },
                    {
                        path: 'service-pro-invoice-queues-board',
                        data: {
                            title: 'Service Pro Invoice Boards',
                            iconClass: 'view_week',
                            description: 'View the Service Pro Invoice Boards',
                        },
                    },

                    {
                        path: 'export-invoices/ServiceFee',
                        data: {
                            title: 'Invoice Export',
                            allowQuickAccess: true,
                            iconClass: 'crop_portrait',
                            description: 'Export service fee invoice A/R to QuickBooks'
                        },
                    },
                    {
                        path: 'service-pro/invoice',
                        hidden: true,
                        data: {
                            title: 'Create Service Pro Invoice',
                            iconClass: 'attach_money',
                        }
                    },
                    {
                        path: 'invoice-queues',
                        data: {
                            title: 'Invoice Queues',
                            allowQuickAccess: true,
                            iconClass: 'all_inbox',
                            description: 'View the Invoice Queues',
                        }
                    },
                    {
                        path: 'invoice-boards',
                        data: {
                            title: 'Invoice Boards',
                            allowQuickAccess: true,
                            iconClass: 'view_array',
                            description: 'View the Invoice Boards',
                        }
                    },
                    {
                        path: 'invoice-batch-print',
                        data: {
                            title: 'Invoice Batch Print',
                            allowQuickAccess: true,
                            iconClass: 'print',
                            description: 'Print past due invoices',
                        }
                    },
                    {
                        path: 'manually-process-scheduled-invoices',
                        data: {
                            title: 'Scheduled Payments',
                            allowQuickAccess: true,
                            iconClass: 'published_with_changes',
                            description: 'Manually process scheduled invoices',
                        }
                    },
                    {
                        path: 'payment-search',
                        data: {
                            title: 'Payment Search',
                            allowQuickAccess: true,
                            iconClass: 'request_page',
                            description: 'Search For Previous Payments'
                        }
                    },
                    {
                        path: 'positive-pay',
                        data: {
                            title: 'Positive Pay Report',
                            allowQuickAccess: true,
                            iconClass: 'flaky',
                            description: 'View the positive pay report/export'
                        }
                    },
                    {
                        path: 'export-maintenance-service-invoices',
                        data: {
                            title: 'Export Maintenance Invoices',
                            iconClass: 'cleaning_services',
                            parentKey: 'accounting',
                        }
                    },
                ]
            },
            {
                path: 'maintenance-services-setup',
                expanded: false,
                data: {
                    title: 'Maintenance Service Setup',
                    allowQuickAccess: true,
                    iconClass: 'cleaning_services',
                    description: 'Setup and configure maintenance services',
                },
                children: [
                    {
                        path: '',
                        data: {
                            title: 'MS Setup Menu',
                            iconClass: 'list',
                            description: 'Maintenance Service Setup Menu'
                        }
                    },
                    {
                        path: 'services',
                        data: {
                            title: 'Maintenace Services',
                            iconClass: 'roofing',
                            description: 'Create and edit base maintenance services'
                        }
                    },
                    {
                        path: 'frequency-types',
                        data: {
                            title: 'Frequency Types',
                            iconClass: 'hourglass_bottom',
                            description: 'Create and edit maintenace service frequency types',
                        }
                    },
                    {
                        path: 'pricing-inputs',
                        data: {
                            title: 'Pricing Inputs',
                            iconClass: 'price_check',
                            description: 'Create and edit maintenance service pricing inputs'
                        }
                    },
                    {
                        path: 'service-offerings',
                        data: {
                            title: 'Service Offerings',
                            iconClass: 'home_repair_service',
                            description: 'Created and edit maintenance service offerings'
                        }
                    },
                    {
                        path: 'global-config',
                        data: {
                            title: 'Maintenance Service Global',
                            iconClass: 'public',
                            description: 'Configures global settings for maintenance services'
                        }
                    }
                ]
            },
            {
                path: 'data-visualization',
                expanded: false,
                data: {
                    title: 'Data Visualization',
                    allowQuickAccess: true,
                    iconClass: 'pie_chart',
                    description: 'Data Visualization'
                },
                children: [
                    {
                        path: 'report-promo-code',
                        data: {
                            title: 'Promo Code Subs Report',
                            allowQuickAccess: true,
                            iconClass: 'receipt_long',
                            description: 'Promo Code Subs Report',
                        }
                    },
                    {
                        path: 'kpi-target-entry',
                        data: {
                            title: 'KPI Target Entry',
                            allowQuickAccess: true,
                            iconClass: 'done',
                            description: 'Enter KPI Targets'
                        }
                    },
                    {
                        path: 'engineering-scoreboard',
                        data: {
                            title: 'Engineering Scoreboard',
                            allowQuickAccess: true,
                            iconClass: 'stacked_bar_chart',
                            description: 'Shows metrics and leaderboard for the engineering team',
                        },
                    },

                    {
                        path: 'trimester-priority-setup',
                        data: {
                            title: 'Trimester Priorites',
                            allowQuickAccess: true,
                            iconClass: 'sports_score',
                            description: 'Setup the trimester priorities and teams',
                        },
                    },
                ]
            },

            {
                path: 'settings',
                expanded: false,
                data: {
                    title: 'Settings',
                    allowQuickAccess: true,
                    iconClass: 'settings',
                    description: 'Settings Menu'
                },
                children: [

                    {
                        path: 'terms-and-limitations',
                        data: {
                            title: 'Terms and Limitations',
                            description: 'Edit the contract terms and limitations',
                            allowQuickAccess: true,
                            iconClass: 'description',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'items/job-items',
                        data: {
                            title: 'Job Items',
                            allowQuickAccess: true,
                            iconClass: 'reorder',
                            description: 'Configure the job items',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },
                    {
                        path: 'authorizations-rules',
                        data: {
                            title: 'Authorization Rules',
                            description: 'Setup the authorization rules',
                            allowQuickAccess: true,
                            iconClass: 'gavel',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },

                    {
                        path: 'real-estate-resources',
                        data: {
                            title: 'Real Estate Resources',
                            description: 'Real Estate Resources',
                            iconClass: 'note_add',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'promotions',
                        data: {
                            title: 'Setup Promotions',
                            description: 'Promotions Setup screen',
                            allowQuickAccess: true,
                            iconClass: 'compare_arrows',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'company-setup',
                        data: {
                            title: 'Company Setup',
                            allowQuickAccess: true,
                            iconClass: 'business',
                            description: 'Company setup screen',
                            parentKey: 'settings',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'trade-categories',
                        data: {
                            title: 'Trade Category Setup',
                            allowQuickAccess: true,
                            iconClass: 'double_arrow',
                            description: 'Trade Category Setup',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },
                    {
                        path: 'email-inbox-monitoring-setup',
                        data: {
                            title: 'Email Inbox Monitoring Setup',
                            allowQuickAccess: true,
                            iconClass: 'inbox',
                            description: 'Setup email inboxes for automatic monitoring',
                            parentKey: 'settings',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'trades',
                        data: {
                            title: 'Trade Setup',
                            allowQuickAccess: true,
                            iconClass: 'group_work',
                            description: 'Trade Setup',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },
                    {
                        path: 'coverage-items',
                        data: {
                            title: 'Coverage Items',
                            allowQuickAccess: true,
                            iconClass: 'shopping_cart',
                            description: 'Configure coverage items',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'job-item-edit',
                        hidden: true,
                        data: { title: 'Job Item Edit' },
                    },
                    {
                        path: 'repair-items',
                        data: {
                            title: 'Repair Items Setup',
                            description: 'Repair Items Setup',
                            iconClass: 'help_outline',
                            parentKey: 'settings',
                            grouping: 'Service',
                        },
                    },

                    {
                        path: 'service-areas',
                        data: {
                            title: 'Service Areas',
                            description: 'Service Areas Setup',
                            iconClass: 'home_repair_service',
                            parentKey: 'settings',
                            grouping: 'Areas'
                        },
                    },

                    {
                        path: 'sales-areas',
                        data: {
                            title: 'Sales Areas',
                            description: 'Sales Areas Setup',
                            iconClass: 'groups',
                            parentKey: 'settings',
                            grouping: 'Areas'
                        },
                    },
                    {
                        path: 'product-areas',
                        data: {
                            title: 'Product Areas',
                            description: 'Product Areas Setup',
                            iconClass: 'shopping_cart',
                            parentKey: 'settings',
                            grouping: 'Areas'
                        },
                    },
                    {
                        path: 'pricing-areas',
                        data: {
                            title: 'Pricing Areas',
                            description: 'Pricing Areas Setup',
                            iconClass: 'money',
                            parentKey: 'settings',
                            grouping: 'Areas'
                        },
                    },
                    {
                        path: 'accounting-areas',
                        data: {
                            title: 'Accounting Areas',
                            description: 'Accounting Areas Setup',
                            iconClass: 'monetization_on',
                            parentKey: 'settings',
                            grouping: 'Areas'
                        },
                    },
                    {
                        path: 'users',
                        data: {
                            title: 'Users Setup',
                            allowQuickAccess: true,
                            iconClass: 'group',
                            description: `Setup ${UtilitiesService.applicationName} Users`,
                            parentKey: 'settings',
                            grouping: 'Security',
                        }
                    },
                    {
                        path: 'plan-styles',
                        data: {
                            title: 'Plan Style Setup',
                            description: 'Setup Plan Styles',
                            allowQuickAccess: true,
                            iconClass: 'style',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'plans',
                        data: {
                            title: 'Plan Setup',
                            description: `Setup of ${UtilitiesService.applicationName} Plans`,
                            allowQuickAccess: true,
                            iconClass: 'receipt',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'plan-groups',
                        data: {
                            title: 'Plan Groups Setup',
                            description: `Setup of ${UtilitiesService.applicationName} Plans`,
                            allowQuickAccess: true,
                            iconClass: 'receipt',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'plan-pricing-adjustments',
                        data: {
                            title: 'Plan Pricing Adjustments',
                            description: 'Maintain the plan pricing adjustments',
                            allowQuickAccess: true,
                            iconClass: 'book',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'sales-item-coverage',
                        data: {
                            title: 'Sales Item Coverage',
                            description: 'Maintain the sales item coverage',
                            allowQuickAccess: true,
                            iconClass: 'bookmark_border',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'accounting-configuration',
                        data: {
                            title: 'Accounting Configuration',
                            description: 'Map accounts to accounting system',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'monetization_on',
                            grouping: 'Other',
                        }
                    },
                    // {
                    //     path: 'edit-template',
                    //     data: {
                    //         title: 'Edit Templates',
                    //         description: 'Edit the template content for various correspondences',
                    //         allowQuickAccess: true,
                    //         iconClass: 'book',
                    //         parentKey: 'settings',
                    //         grouping: 'Other',
                    //     }
                    // },
                    {
                        path: 'marketing-sources',
                        data: {
                            title: 'Marketing Sources Setup',
                            description: 'Setup the available marketing sources',
                            allowQuickAccess: true,
                            iconClass: 'bookmarks',
                            parentKey: 'settings',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'purchase-order-items',
                        data: {
                            title: 'Purchase Order Items',
                            description: 'Setup the available purchase order items',
                            allowQuickAccess: true,
                            iconClass: 'line_weight',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },
                    // {
                    //     path: 'repair-item-collections',
                    //     data: {
                    //         title: 'Repair Item Collections',
                    //         description: 'Setup the repair item collections',
                    //         allowQuickAccess: true,
                    //         iconClass: 'dns',
                    //         parentKey: 'settings',
                    //         grouping: 'Service',
                    //     }
                    // },

                    {
                        path: 'service-request-status-setup',
                        data: {
                            title: 'Service Request Status Setup',
                            description: 'Service Request Status Setup',
                            allowQuickAccess: true,
                            iconClass: 'label_important',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },
                    {
                        path: 'object-commands',
                        data: {
                            title: 'Object Commands',
                            description: 'Object Commands',
                            allowQuickAccess: true,
                            iconClass: 'flash_on',
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'global-commands-setup',
                        data: {
                            title: 'Global Commands Setup',
                            description: 'Global Commands Setup',
                            allowQuickAccess: true,
                            iconClass: 'language',
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'tag-setup',
                        data: {
                            title: 'Tag Setup',
                            description: 'Tag Setup',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'outlined_flag',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'roles',
                        data: {
                            title: 'Roles Setup',
                            description: 'Setup the available user Roles',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'lock',
                            grouping: 'Security',
                        }
                    },
                    {
                        path: 'security-filters',
                        data: {
                            title: 'Security Filters Setup',
                            description: 'Setup security filters for entity types/roles.',
                            allowQuickAccess: true,
                            iconClass: 'line_weight',
                            parentKey: 'settings',
                            grouping: 'Security',
                        },
                    },
                    {
                        path: 'permissions',
                        data: {
                            title: 'Permissions Setup',
                            description: 'Setup the available system permissions',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'lock',
                            grouping: 'Security',
                        }
                    },
                    {
                        path: 'trigger-setup',
                        data: {
                            title: 'Trigger Setup',
                            description: 'Trigger Setup',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'network_check',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'holiday-setup',
                        data: {
                            title: 'Holiday Setup',
                            description: 'Holiday Setup',
                            allowQuickAccess: true,
                            iconClass: 'cake',
                            parentKey: 'settings',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'questionnaire-setup',
                        data: {
                            title: 'Questionnaire Setup',
                            description: 'Questionnaire Setup',
                            allowQuickAccess: true,
                            iconClass: 'help',
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'quickbooks-connect',
                        data: {
                            title: 'Quickbooks Connect',
                            description: 'Connect to Quickbooks Online',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            grouping: 'Accounting',
                            iconClass: 'help_outline'
                        }
                    },
                    {
                        path: 'form-setup',
                        data: {
                            title: 'Custom Form Setup',
                            description: 'Custom Form Setup',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'ballot',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'engineering-options',
                        data: {
                            title: 'Engineering Options',
                            description: 'Engineering Options',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'settings_input_composite',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'dynamic-service-fee-rules',
                        data: {
                            title: 'Dynamic Service Fee Rules',
                            description: 'Setup rules for creating dynamic service fees',
                            allowQuickAccess: true,
                            iconClass: 'dynamic_form',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },
                    {
                        path: 'dynamic-authorization-amount-rules',
                        data: {
                            title: 'Dynamic Authorization Amount Rules',
                            description: 'Setup rules for calculating dynamic service fee amounts',
                            allowQuickAccess: true,
                            iconClass: 'price_change',
                            parentKey: 'settings',
                            grouping: 'Service',
                        }
                    },
                    {
                        path: 'service-pro-swim-lane-setup',
                        data: {
                            title: 'Service Pro Swim Lane Setup',
                            description: 'Service Pro Swim Lane Setup',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'view_column',
                            grouping: 'Queues/Boards/Commands'
                        }
                    },
                    {
                        path: 'edit-settings-variables',
                        data: {
                            title: 'Edit Settings Variables',
                            description: 'Edit settings variables',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'settings_ethernet',
                            grouping: 'Other',
                        },
                    },
                    {
                        path: 'layout-templates',
                        data: {
                            title: 'Edit Communication Templates',
                            description: 'Edit Communication Templates',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'short_text',
                        }
                    },
                    {
                        path: 'bi-reports-setup',
                        data: {
                            title: 'BI Reports Setup',
                            description: 'BI Reports Setup',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'pivot_table_chart',
                        }
                    },
                    {
                        path: 'theme-setup',
                        data: {
                            title: 'Theme Setup',
                            description: 'Customize the Cogent Theme',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'format_size',
                            grouping: 'Other',
                        }
                    },
                    {
                        path: 'items/work-order-item-question',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Job Item Question Editor'
                        }
                    },
                    {
                        path: 'items/autho-question',
                        hidden: true,
                        data: {
                            hidden: true,
                            title: 'Autho Question Editor'
                        }
                    },
                    {
                        path: 'questionnaire-edit',
                        hidden: true,
                        data: { hidden: true },
                    },
                    {
                        path: 'workflow-edit',
                        hidden: true,
                        data: { hidden: true },
                    },
                    {
                        path: 'system-task-queues-setup',
                        data: {
                            allowQuickAccess: true,
                            title: 'System Task Queues',
                            description: 'System Task Queues Setup',
                            iconClass: 'system_update_alt',
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'shipper-setup',
                        data: {
                            title: 'Shipper Setup',
                            description: 'Shipper Setup',
                            allowQuickAccess: true,
                            iconClass: 'local_shipping',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },
                    {
                        path: 'cancellation-reason-setup',
                        data: {
                            title: 'Cancellation Reason Setup',
                            description: 'Modify the list of subscription cancellation reasons',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'remove_from_queue',
                            grouping: 'Sales',
                        }
                    },
                    {
                        path: 'webhooks',
                        data: {
                            title: 'Webhooks',
                            description: 'Configure Webhooks endpoints',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'http',
                            grouping: 'Other'
                        }
                    },
                    {
                        path: 'dynamic-component-setup',
                        data: {
                            title: 'Dynamic Component Setup',
                            description: 'Create and edit dynamic components',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'code',
                            grouping: 'Queues/Boards/Commands'
                        }
                    },
                    {
                        path: 'custom-table-setup',
                        data: {
                            title: 'Custom Table Setup',
                            description: 'Create user defined tables',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'backup_table',
                            grouping: 'Queues/Boards/Commands',
                        }
                    },
                    {
                        path: 'purchase-order-status-setup',
                        data: {
                            title: 'Purchase Order Status Setup',
                            description: 'Configure the statuses for purchase order lifecycles',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'dynamic_feed',
                            grouping: 'Service',
                        }
                    },
                    {
                        path: 'feature-updates',
                        data: {
                            title: 'Feature Updates',
                            description: 'Create / Modify feature updates',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'try'
                        }
                    },

                    {
                        path: 'edit-transfer-reasons',
                        data: {
                            title: 'Transfer Reasons',
                            description: 'Create / Modify Transfer Reasons',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            iconClass: 'shuffle',
                            grouping: 'Service',
                        }
                    },

                    {
                        path: 'workflow-setup',
                        data: {
                            title: 'Workflow Setup',
                            description: 'Workflow Setup',
                            iconClass: 'developer_board',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands'
                        }
                    },
                    {
                        path: 'function-editor',
                        data: {
                            title: 'Function Editor',
                            description: 'Create and modify cogent functions',
                            iconClass: 'psychology',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                            grouping: 'Queues/Boards/Commands'
                        }
                    },
                    {
                        path: 'part-markup-allowance',
                        data: {
                            title: 'Parts Markup Allowance',
                            description: 'Setup Parts Markup Allowances',
                            iconClass: 'add_shopping_cart',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },

                    {
                        path: 'create-employee',
                        data: {
                            title: 'Create Employee',
                            description: 'Creates a new employee and configures Azure/Connect',
                            iconClass: 'person_add',
                            parentKey: 'settings',
                            grouping: 'Security'
                        }
                    },

                    {
                        path: 'sms-phone-mapping',
                        data: {
                            title: 'SMS Phone Mapping',
                            description: 'Maps Twilio SMS numbers to an Amazon Connect contact flow',
                            iconClass: 'multiple_stop',
                            allowQuickAccess: true,
                            parentKey: 'settings',
                        },
                    },
                    {
                        path: 'ai-grounding-data',
                        data: {
                            title: 'AI Grounding Data',
                            description: 'Configure AI chat grounding data',
                            iconClass: 'smart_toy',
                            parentKey: 'settings',
                        },
                    },
                    {
                        path: 'autho-item-setup',
                        data: {
                            title: 'Autho Item Setup',
                            description: 'Setup and maintain authorization items',
                            iconClass: '',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },
                    {
                        path: 'default-autho-repair-items',
                        data: {
                            title: 'Default Repair Items',
                            description: 'Setup groups of default repair items for types of repairs',
                            iconClass: 'group_work',
                            parentKey: 'settings',
                            grouping: 'Service'
                        }
                    },
                    {
                        path: 'bundle-and-save-setup',
                        data: {
                            title: 'Bundle & Save Setup',
                            description: 'Setup the bundle and save discount for warranty and HMS',
                            iconClass: 'discount',
                            parentKey: 'settings',
                            grouping: 'Sales'
                        }
                    }

                ]
            },
        ];
    }
}