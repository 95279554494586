import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { OrdersByMonth } from '@cogent/shared/models/sales/orders-by-month.model';
import { SalesReport } from '@cogent/client/shared/models/sales/sales-report.model';
import {
    BI_RenewalPercent, BiDsa, BiTopAgent, CommissionableOrder, Entity, FavorFundBudget, FavorFundBudgetSummary,
    FavorFundExpense, FavorFundExpenseSummary, FavorFundWeight, FavorFundWeightItem, FavorFundWeightItemSummary, KeyMetricGoal,
    PolicySummaryV2, PromoCodeSub, Quote, QuoteAndOrderState, RenewalGoal, SalesCommissionReport, SalesEmployeeTarget, SalesOrderTarget,
    SalesRegionTarget, WebQuoteSummary
} from '@upkeeplabs/models/cogent';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SalesApiService {
    getWebQuoteSummary(id: any) {
        return this.api.getSingleNode(`WebQuoteSummary/${id}`, null, () => new WebQuoteSummary());
    }
    constructor(private api: ApiService, private entityApi: EntityApiService) { }

    getKeyMetricGoals() {
        return this.api.getArrayNode('KeyMetricGoal', { deletedDate_eq: null }, () => new KeyMetricGoal());
    }

    saveCombinedOrder(state: QuoteAndOrderState) {
        return this.api.postArrayNode('sales/order', state);
    }

    getKeyMetricGoalsByYear(year: number) {
        return this.api.getArrayNode('KeyMetricGoal', { metricYear_eq: year, deletedDate_eq: null }, () => new KeyMetricGoal());
    }

    saveKeyMetricGoal(goal: KeyMetricGoal) {
        return this.api.postNode(`KeyMetricGoal`, goal);
    }

    getTop100Agents() {
        return this.api.getArrayNode(`mls/get-top-agents`, null, () => new BiTopAgent());
    }

    getDsaReport() {
        return this.api.getArrayNode(`mls/get-dsa`, null, () => new BiDsa())
    }

    getFavorFundExpenses(employeeId: string) {
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { employeeId_eq: employeeId }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpenses_Year(year: number) {
        if (!year) year = new Date().getFullYear();
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { createdYear_eq: year }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpensesByYear(employeeId: string, year: number) {
        if (!year) year = new Date().getFullYear();
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { createdYear_eq: year, employeeId_eq: employeeId }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpenseByInvoiceId(id: string, premiumIndex: number) {
        if (premiumIndex > 0)
            return this.api.getSingleNode(`FavorFundExpenseSummary`, { premiumInvoiceId_eq: id }, () => new FavorFundExpenseSummary());
        return this.api.getSingleNode(`FavorFundExpenseSummary`, { serviceFeeInvoiceId_eq: id }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpenseByWorkOrderLineId(id: string) {
        return this.api.getSingleNode(`FavorFundExpenseSummary`, { workOrderLineId_eq: id }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpensesByWorkOrderLineId(id: string) {
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { workOrderLineId_eq: id }, () => new FavorFundExpenseSummary());

    }

    getFavorFundExpensesByWorkOrderId(id: string) {
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { workOrderId_eq: id }, () => new FavorFundExpenseSummary());
    }

    getFavorFundExpenseCashOut(cashOutId: string) {
        return this.api.getSingleNode(`FavorFundExpense`, { cashOutId_eq: cashOutId }, () => new FavorFundExpense);
    }

    getFavorFundExpensePurchaseOrder(purchaseOrderId: string) {
        return this.api.getSingleNode(`FavorFundExpense`, { purchaseOrderId_eq: purchaseOrderId }, () => new FavorFundExpense);
    }

    getFavorFundExpense(id: string) {
        return this.api.getSingleNode(`FavorFundExpense`, { id_eq: id }, () => new FavorFundExpense);
    }

    getFavorFundBalance(policyId: string) {
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { policyId_eq: policyId }, () => new FavorFundExpenseSummary());
    }

    voidFavorFundExpense(id: string) {
        return this.api.patchNode('FavorFundExpense/' + id, { deletedDate: new Date() });
    }

    unDeleteFavorFundExpense(id: string) {
        return this.api.unDeleteNode(`FavorFundExpense/${id}`);
    }

    generateFavorFundBudgets(newYear: number, budgets: FavorFundBudgetSummary[]) {
        const jSon = JSON.stringify(budgets);
        return this.api.postVoidNode(`favor-fund/generate-favor-fund-budgets/${newYear}`, { budgets }, () => new FavorFundBudgetSummary());
    }

    getFavorFundYears() {
        return this.api.getArrayNode(`favor-fund/get-years`);
    }

    getActiveFavorFundBudgets(year: number) {
        return this.api.getArrayNode(`FavorFundBudgetSummary`, { year_eq: year, inactiveDate_eq: '{{null}}' }, () => new FavorFundBudgetSummary());
    }

    getFavorFundBudgets(year: number) {
        return this.api.getArrayNode(`FavorFundBudgetSummary`, { year_eq: year }, () => new FavorFundBudgetSummary());
    }

    getFavorFundBudget(year: number, employeeId: string) {
        return this.api.getSingleNode(`FavorFundBudgetSummary`, { year_eq: year, employeeId_eq: employeeId }, () => new FavorFundBudgetSummary());
    }

    getFavorFundBudgetById(id: string) {
        return this.api.getSingleNode(`FavorFundBudget`, { id_eq: id }, () => new FavorFundBudget());
    }

    async deleteFavorFundBudget(id: string) {
        const budget = await this.getFavorFundBudgetById(id);
        budget.deletedDate = new Date();
        return this.api.postSingleNode(`FavorFundBudget`, budget);
    }
    saveFavorFundBudget(budget: FavorFundBudget) {
        return this.api.postSingleNode(`FavorFundBudget`, budget);
    }
    getFavorFundWeight(id: string) {
        return this.api.getSingleNode(`FavorFundWeight`, { id_eq: id }, () => new FavorFundWeight());
    }

    getFavorFundWeights() {
        return this.api.getArrayNode(`FavorFundWeight`, { deletedDate_eq: '{{null}}' }, () => new FavorFundWeight());
    }

    getFavorFundWeightItems(favorFundWeightId?: string) {
        if (favorFundWeightId)
            return this.api.getArrayNode(`FavorFundWeightItemSummary`, { favorFundWeightId_eq: favorFundWeightId }, () => new FavorFundWeightItemSummary());

        return this.api.getArrayNode(`FavorFundWeightItemSummary`, null, () => new FavorFundWeightItemSummary());
    }

    async saveFavorFundWeightItems(weight: FavorFundWeight) {
        let promises = [];
        for (const item of weight.items) {
            let newItem = new FavorFundWeightItem();
            newItem.id = item.id;
            newItem.favorFundWeightId = item.favorFundWeightId;
            newItem.month = item.month;
            newItem.weight = item.weight;
            promises.push(this.saveFavorFundWeightItem(newItem));
        }
        return Promise.all(promises);
    }

    async saveFavorFundWeightItem(item: FavorFundWeightItem): Promise<FavorFundWeightItem> {
        return this.api.postSingleNode(`FavorFundWeightItem`, item);
    }

    async saveFavorFundExpense(item: FavorFundExpense): Promise<FavorFundExpense> {
        return this.api.postSingleNode(`FavorFundExpense`, item);
    }

    async saveFavorFundWeight(item: FavorFundWeight): Promise<FavorFundWeight> {
        return await this.api.postSingleNode(`FavorFundWeight`, item);
    }

    getRenewalPercent(startDate: Date, endDate: Date) {
        return this.api.getArrayNode('BI_RenewalPercent', { expirationMonth_gte: startDate, expirationMonth_lte: endDate }, () => new BI_RenewalPercent());
    }

    getRenewalGoals(coverageType: string, startDate: Date, endDate: Date) {
        if (startDate) {
            return this.api.getArrayNode('RenewalGoal', { date_gte: startDate, date_lte: endDate }, () => new RenewalGoal());
        }
        if (!coverageType)
            return this.api.getArrayNode('RenewalGoal', null, () => new RenewalGoal());
        else
            return this.api.getArrayNode('RenewalGoal', { coverageType_eq: coverageType }, () => new RenewalGoal());

    }

    saveRenewalGoals(renewalGoals: RenewalGoal) {
        return this.api.postNode(`RenewalGoal`, renewalGoals);
    }

    getAccountExecutiveByPostalCode(postalCode: string) {
        return this.api.getSingleNode(`entity/find-account-executive-by-postalcode/${postalCode}`);
    }

    getSalesTerritories() {
        return this.api.getArrayNode('Entity', { type_eq: 'SalesTerritory', select: 'id,name', orderby: 'name' });
    }

    async getSalesEmployeeTargets(startDate: Date, endDate: Date, channel: string): Promise<SalesEmployeeTarget[]> {
        startDate.setDate(1);
        endDate = UtilitiesService.monthEnd(endDate);
        const items: SalesEmployeeTarget[] = await this.api.getArrayNode(`SalesEmployeeTarget`,
            { month_gte: startDate, month_lte: endDate, orderby: 'month', channel_eq: channel });
        const salesRegions: string[] = await this.api.getArrayDotNet(`Entity`, { type_eq: 'SalesTerritory', select: 'id' });
        const employees = await this.entityApi.getActiveEmployees();
        const employeeIds = [...new Set(employees.map(i => i.id))];
        items.forEach(item => {
            if (item.month.getDate() !== 1) {
                item.month.setDate(item.month.getDate() + 1);
            }
        });

        for (const employeeId of employeeIds) {
            const testDate = new Date(startDate);
            while (testDate <= endDate) {

                let target = items.filter(i => i.month.getMonth() === testDate.getMonth() && i.month.getFullYear() === testDate.getFullYear() && i.employeeId === employeeId)[0];
                if (!target) {
                    target = new SalesEmployeeTarget();
                    target.id = UtilitiesService.newid();
                    target.employeeId = employeeId;
                    target.salesTarget = 0;
                    target.month = new Date(testDate.getFullYear(), testDate.getMonth(), 1);
                    target.isDirty = true;
                    target.channel = channel;
                    items.push(target);

                }
                testDate.setMonth(testDate.getMonth() + 1);
            }
        }

        return items;
    }

    async getSalesTargets(startDate: Date, endDate: Date, channel: string, selectedSalesAreaIds: string[]): Promise<SalesRegionTarget[]> {
        startDate.setDate(1);
        endDate = UtilitiesService.monthEnd(endDate);

        const items: SalesRegionTarget[] = await this.api.getArrayDotNet(`SalesRegionTarget`,
            { month_gte: startDate, month_lte: endDate, orderby: 'month', channel_eq: channel, salesRegionId_contains: this.api.flattenStringArguments(selectedSalesAreaIds) });
        const salesRegions: string[] = await this.api.getArrayDotNet(`Entity`, { type_eq: 'SalesTerritory', select: 'id' });
        items.forEach(item => {
            if (item.month.getDate() !== 1) {
                item.month.setDate(item.month.getDate() + 1);
            }
        });

        for (const salesRegion of salesRegions) {
            const testDate = new Date(startDate);
            while (testDate <= endDate) {

                let target = items.filter(i => i.month.getMonth() === testDate.getMonth() && i.month.getFullYear() === testDate.getFullYear() && i.salesRegionId === salesRegion)[0];
                if (!target) {
                    target = new SalesRegionTarget();
                    target.id = UtilitiesService.newid();
                    target.salesRegionId = salesRegion;
                    target.salesTarget = 0;
                    target.month = new Date(testDate.getFullYear(), testDate.getMonth(), 1);
                    target.isDirty = true;
                    target.channel = channel;
                    items.push(target);

                }
                testDate.setMonth(testDate.getMonth() + 1);
            }
        }

        return items;
    }

    markQuoteComplete(quoteId: string, policyId: string) {
        return this.api.patchSingleDotNet(`Quote/${quoteId}`, {
            completionDate: new Date(),
            policyId
        });
    }

    async getBranchAgents() {
        const user = await this.entityApi.getLoggedInUser(false);
        return this.api.getArrayDotNet(`EntitySummary`,
            { parentId_eq: user.parentId, orderby: 'name', select: 'id,name,email,mobileNumber,workNumber', type_eq: 'Agent' });
    }

    async getReferrerExpensesByTrade() {
        const user = await this.entityApi.getLoggedInUser(false);

        return this.api.getArrayDotNet(`entity/${user.id}/referrer-expenses-by-trade-login`);
    }

    emailQuote(id: string, email: string) {
        return this.api.getArrayDotNet(`Quote/${id}/email`, { email });
    }

    getAgentTopOrders(startDate: Date, endDate: Date, count: number, salesRegionIds: string[]) {
        return this.api.getArrayDotNet(`SalesReport/top-orders`, { startDate, endDate, count, salesRegionIds });
    }

    getClosingOfficerTopOrders(startDate: Date, endDate: Date, count: number, salesRegionIds: string[]) {
        return this.api.getArrayDotNet(`SalesReport/closing-officer-top-orders`, { startDate, endDate, count, salesRegionIds });
    }


    getTitleCompanyTopOrders(startDate: Date, endDate: Date, count: number, salesRegionIds: string[]) {
        return this.api.getArrayDotNet(`SalesReport/title-top-orders`, { startDate, endDate, count, salesRegionIds });

    }

    getAgentOfficeTopOrders(startDate: Date, endDate: Date, count: number, salesRegionIds: string[]) {
        return this.api.getArrayDotNet(`SalesReport/agent-office-top-orders`, { startDate, endDate, count, salesRegionIds });
    }

    getBrokerageTopOrders(startDate: Date, endDate: Date, count: number, salesRegionIds: string[]) {
        return this.api.getArrayDotNet(`SalesReport/brokerage-top-orders`, { startDate, endDate, count, salesRegionIds });
    }

    async getPromoCodeSubs(startDate: Date, endDate: Date): Promise<PromoCodeSub[]> {
        return await this.api.getArrayNode(`sales-commission/promo-code-subs`, { startDate, endDate }, () => new PromoCodeSub());
        // await this.api.getArray2('sales-commission/report', { startDate, endDate }, () => new SalesCommissionReport);

    }

    getAgentTopOrdersDetail(startDate: Date, endDate: Date, salesRegionIds: string[], agentId: string) {
        const params: any = {
            createdDate_gte: startDate,
            createdDate_lte: endDate,
            agentId_eq: agentId,
            orderby: 'createdDate desc',
            select: 'id,createdDate,policyNumber,planName,holder,propertyAddress'
        };
        if (salesRegionIds && salesRegionIds.length > 0) {
            params.salesTerritoryId_contains = salesRegionIds.join(',');
        }
        return this.api.getArrayDotNet(`PolicySummary`, params, () => new PolicySummary())
    }

    getPoliciesByTeam(teamId: string) {
        return this.api.getArrayNode(`PolicySummaryV2`, { buyersAgentTeamId_eq: teamId }, () => new PolicySummaryV2());
    }

    getClosingOfficerTopOrdersDetail(startDate: Date, endDate: Date, salesRegionIds: string[], closingOfficerId: string) {
        const params: any = {
            createdDate_gte: startDate,
            createdDate_lte: endDate,
            closingOfficerId_eq: closingOfficerId,
            orderby: 'createdDate desc',
            select: 'id,createdDate,policyNumber,planName,holder,propertyAddress'
        };
        if (salesRegionIds && salesRegionIds.length > 0) {
            params.salesRegionId_contains = salesRegionIds.join(',');
        }
        return this.api.getArrayDotNet(`PolicySummary`, params, () => new PolicySummary())
    }

    getTitleCompanyTopOrdersDetail(startDate: Date, endDate: Date, salesRegionIds: string[], titleCompanyId: string) {
        const params = {
            startDate,
            endDate,
            salesRegionIds,
            titleCompanyId,
        };

        return this.api.postArrayDotNet(`SalesReport/title-top-orders-detail`, params, null, () => new PolicySummary());
    }

    getAgentOfficeTopOrdersDetail(startDate: Date, endDate: Date, salesRegionIds: string[], agentOfficeId: string) {
        return this.api.getArrayDotNet(`SalesReport/agent-office-top-orders-detail`, { startDate, endDate, salesRegionIds, agentOfficeId }, () => new PolicySummary());
    }

    getBrokerageTopOrdersDetail(startDate: Date, endDate: Date, salesRegionIds: string[], brokerageId: string) {
        return this.api.getArrayDotNet(`SalesReport/brokerage-top-orders-detail`, { startDate, endDate, salesRegionIds, brokerageId }, () => new PolicySummary());
    }

    getMyAE() {
        return this.api.getSingleDotNet('entity/get-my-assigned-ae');
    }

    getSalesRegions(): Promise<Entity[]> {
        return this.api.getArrayDotNet(`Entity`, { type_eq: 'Sales_Region', select: 'id,name' });
    }

    saveSalesTargets(targets: SalesRegionTarget[]) {
        return this.api.postArrayDotNet(`SalesRegionTarget/PostList`, targets);
    }

    saveQuote(quote: Quote) {
        return this.api.postSingleDotNet(`Quote`, quote);
    }

    getSalesReport(useEffectiveDate: boolean, startDate: Date, endDate: Date, areaIds: string,
        showBuyersCoverage, showSellersCoverage, showHomeownerCoverage, showRenewalCoverage, showPaidOnly: boolean, showRealEstateRunOff: boolean,
        showPropertyManagementCoverage: boolean): Promise<SalesReport[]> {

        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);

        return this.api.getArrayDotNet(`SalesReport`, {
            useEffectiveDate, startDate, endDate, areaIds, showBuyersCoverage,
            showSellersCoverage, showHomeownerCoverage, showRenewalCoverage,
            showPaidOnly, showRealEstateRunOff, showPropertyManagementCoverage
        }, () => new SalesReport());
    }

    async getCommissionableOrders(startDate: Date, endDate: Date) {
        const values = await this.api.getArrayNode('sales-commission/commissionable-orders', { startDate, endDate }, () => new CommissionableOrder);

        return values;
    }

    getOrderTargets(startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`SalesOrderTarget`, {
            month_gte: (startDate.getMonth() + 1), year_gte: startDate.getFullYear(),
            month_lte: (endDate.getMonth() + 1), year_lte: endDate.getFullYear()
        }, () => new SalesOrderTarget());
    }

    saveOrderTargets(targets: SalesOrderTarget[]) {
        return this.api.postSingleNode(`SalesOrderTarget`, targets[0]);
    }

    async getSalesCommissionReport(startDate: Date, endDate: Date) {
        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const values = await this.api.getArrayNode('sales-commission/report', { startDate, endDate }, () => new SalesCommissionReport);
        const datePipe = new DatePipe('en-US');
        for (const value of values) {
            value["displayMonth"] = datePipe.transform(value.month, "MMM yyyy");
        }
        return values;
    }

    getSalesReportDetail(useEffectiveDate: boolean, startDate: Date, endDate: Date, areaIds: string, showBuyersCoverage,
        showSellersCoverage, showHomeownerCoverage, showRenewalCoverage, month: Date, salesRegionId: string, salesAreaId: string,
        coverageType: string, salesTerritoryId: string, showPaidOnly: boolean, showRealEstateRunOff: boolean,
        showPropertyManagmentCoverage: boolean): Promise<PolicySummaryV2[]> {

        const params: any = {
            select: 'id,closingCompanyId,propertyAddress1,planName,basePrice,basePriceRecurring,totalPrice,totalPriceRecurring,isMonthly,policyNumber,closingCompanyName,agentName,agentId,holderName',
        };
        if (month) {
            startDate = UtilitiesService.monthStart(month);
            endDate = UtilitiesService.monthEnd(month);
        }
        if (useEffectiveDate) {
            params.effectiveDate_gte = startDate;
            params.effectiveDate_lte = endDate;
        } else {
            params.createdDate_gte = startDate;
            params.createdDate_lte = endDate;
        }
        if (salesRegionId) {
            params.salesRegionId_eq = salesRegionId;
        }
        if (salesAreaId) {
            params.salesAreaId_eq = salesAreaId;
        }
        if (salesAreaId == "unknown") {
            params.salesAreaId_eq = "{{null}}";
        }
        if (salesTerritoryId) {
            params.salesTerritoryId_eq = salesTerritoryId;
        }
        if (salesTerritoryId == "unknown") {
            params.salesTerritoryId_eq = "{{null}}";
        }
        if (showPaidOnly) {
            params.IsPaid_eq = true;
        }

        if (!coverageType) {
            params.salesReportCoverageType_contains = '';
            if (showBuyersCoverage) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'RealEstate';
            }
            if (showSellersCoverage) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'Sellers';
            }
            if (showRealEstateRunOff) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'RealEstateRunOff';
            }
            if (showPropertyManagmentCoverage) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'PropertyManagement';
            }
            if (showHomeownerCoverage) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'Homeowner';
            }
            if (showRenewalCoverage) {
                if (params.salesReportCoverageType_contains) {
                    params.salesReportCoverageType_contains += ',';
                }
                params.salesReportCoverageType_contains += 'Renewal';
            }
        } else {
            params.salesReportCoverageType_contains = coverageType;
        }
        params.canceledDate_eq = '{{null}}';

        return this.api.getArrayNode(`PolicySummaryV2`, params, () => new PolicySummaryV2());
    }

    getPropertyMeta(streetAddress: string, postalCode: string): Promise<PropertyMetaModel> {
        return this.api.getSingleDotNet('PropertyMeta', {
            address: streetAddress,
            zip: postalCode
        });
    }

    getQuotes(): Promise<Quote[]> {
        return this.api.getArrayDotNet(`Quote`, {
            orderby: 'CreatedDate desc',
            entityId_eq: '{{currentUser}}',
            completionDate_eq: '{{null}}',
            select: 'id, propertyAddress1,planName,total,addressId',
        }, () => new Quote());
    }

    getQuote(id: string): Promise<Quote> {
        return this.api.getSingleDotNet(`Quote/${id}`, null, () => new Quote());
    }

    deleteQuote(id: string): Promise<any> {
        return this.api.deleteDotNet(`Quote/${id}`);
    }

    unDeleteQuote(id: string): Promise<any> {
        return this.api.unDeleteDotNet(`Quote/${id}`);
    }

    async getOrdersByMonth(startDate: Date, endDate: Date, includedMarketAreaIds: string[], excludedMarketAreaIds: string[],
        includedMarketStateIds: string[], excludedMarketStateIds: string[], includedRegionIds: string[], excludedRegionIds: string[],
        channels: string[], groupResults: boolean, useEffectiveDate: boolean): Promise<OrdersByMonth[]> {
        const params = {
            reportMonth_gte: startDate,
            reportMonth_lte: endDate,
        } as any;

        if (includedMarketAreaIds && includedMarketAreaIds.length > 0) {
            let pString = '';
            includedMarketAreaIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.MarketAreaId_contains = pString;
        }

        if (excludedMarketAreaIds && excludedMarketAreaIds.length > 0) {
            let pString = '';
            excludedMarketAreaIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.MarketAreaId_ncontains = pString;
        }

        if (includedMarketStateIds && includedMarketStateIds.length > 0) {
            let pString = '';
            includedMarketStateIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.MarketStateId_contains = pString;
        }

        if (excludedMarketStateIds && excludedMarketStateIds.length > 0) {
            let pString = '';
            excludedMarketStateIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.MarketStateId_ncontains = pString;
        }

        if (includedRegionIds && includedRegionIds.length > 0) {
            let pString = '';
            includedRegionIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.SalesRegionId_contains = pString;
        }

        if (excludedRegionIds && excludedRegionIds.length > 0) {
            let pString = '';
            excludedRegionIds.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.SalesRegionId_ncontains = pString;
        }
        if (channels && channels.length > 0) {
            let pString = '';
            channels.forEach(id => {
                if (pString) {
                    pString += ',';
                }
                pString += id;
            });
            params.Channel_contains = pString;
        }

        const ordersByMonth = await this.api.getArrayDotNet(useEffectiveDate ? 'ClosingsByMonth' : 'OrdersByMonth', params, () => new OrdersByMonth());


        ordersByMonth.forEach(order => {
            if (order.reportMonth.getDate() > 1) {
                order.reportMonth.setDate(order.reportMonth.getDate() + 1);
            }
        });

        const results = [];
        if (groupResults) {
            ordersByMonth.forEach(order => {
                let result = results.filter(i => i.reportMonth.getFullYear() === order.reportMonth.getFullYear()
                    && i.reportMonth.getMonth() === order.reportMonth.getMonth()
                    && i.reportMonth.getDate() === order.reportMonth.getDate())[0];
                if (!result) {
                    result = new OrdersByMonth();
                    result.policyCount = 0;
                    result.reportMonth = order.reportMonth;
                    result.cancelledCount = 0;
                    result.salesTarget = 0;
                    results.push(result);
                }

                result.policyCount += order.policyCount;
                result.cancelledCount += order.cancelledCount;
                result.salesTarget += order.salesTarget;
            });
        }

        return groupResults ? results : ordersByMonth;
    }
}