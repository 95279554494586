import { Injectable } from "@angular/core";
import { WorkOrderStatsModel } from '@cogent/shared/models/service/work-order-stats.model';
import { ApiService, ResultExtractionType } from '@cogent/client/api';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { Address, AmazonContact, ClosedWorkOrdersSummary, Entity, InvoiceSummary, PolicyExclusion, PrepaidServiceFee, ProblemCause, RepairItem, ReplacementReason, Tag, Trade, WhiteGloveJobItem, WorkOrderItemCause, WorkOrderItemLocation, WorkOrderItemProblem, WorkOrderItemProblemRepairItem, WorkOrderItemProblemType, WorkOrderItemType, WorkOrderLine, WorkOrderLineAuthorization, WorkOrderPreferredTime, WorkOrderPreferredTimeSummary, WorkOrderStatus, WorkOrderStatusChange, WorkOrderSurveySummary } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { TechnicianContact } from '@cogent/shared/models/authorizations/technician-contact.model';
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { Authorization, WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { Task } from '@upkeeplabs/models/cogent';
import { RecallModel } from '@cogent/shared/models/service/recall.model';
import { ContractorSelection } from '@cogent/shared/models/service/contractor-selection.model';
import { CompanyRegion } from '@cogent/shared/models/common/company-states-and-regions.model';
import { AppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { WorkOrderServiceFeeCancellationInfo } from '@cogent/shared/models/service/work-order-service-fee-cancellation-info.model';
import { ContractorSurveyResult } from '@cogent/shared/models/service/contractor-survey-result.model';
import {
    AuthorizationRule, Claim, PartToOrder, PurchaseOrderItemSummary,
    SalesItemCoverageApplianceCoverageTerm, SalesItemCoverageWorkOrderItemFees, ContractorProposedWorkOrderSummary, ConvertProposedWorkOrderToWorkOrderArgs, ContractorInvoicePaymentSummary, ContractorInvoice
} from "@upkeeplabs/models/cogent";
import { AuthorizationRequestArgs } from '@cogent/shared/models/authorizations/authorization-request-args.model';
import { AlternateTimeSlotSuggestion } from "@cogent/shared/models/service/alternate-time-slot-suggestion.model";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { AuthorizationLine } from "@cogent/shared/models/authorizations/authorization-line.model";
import { SalesItemCoverageClient } from "@cogent/client/shared/models/plans/sales-item-coverage-client.model";
import { WorkOrderClient } from "@cogent/client/shared/models/service/work-order-client.model";
import { ContractorInvoiceSummaryClient } from "@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model";
import { NoteAttachment, NoteClient } from "@cogent/client/shared/models/common/note-client.model";

@Injectable({ providedIn: 'root' })
export class ServiceApiService {

    constructor(private api: ApiService) { }

    declineContractorProposedWorkOrder(id: string) {
        return this.api.patchNode(`ContractorProposedWorkOrder/${id}`, { rejectedDate: new Date() });
    }
    updateTripFee(workOrderId: string, tripFeeAllowance: number) {
        return this.api.patchNode(`WorkOrder/${workOrderId}`, { tripFeeAllowance });
    }

    getUnassignedWhiteGlove() {
        return this.api.getSingleNode(`work-order/unassigned-white-glove-jobs`);
    }
    getWorkOrderLineSummary(id: string) {
        return this.api.getSingleDotNet(`WorkOrderLineSummary`, { id_eq: id }, () => new WorkOrderLineSummaryClient());
    }
    getWorkOrderLineSummary2(id: string) {
        return this.api.getSingleNode(`WorkOrderLineSummary`, { id_eq: id });
    }
    getWorkOrderItemProblemRepairItems(workOrderItemProblemId: string): Promise<WorkOrderItemProblemRepairItem[]> {
        return this.api.getArrayDotNet('WorkOrderItemProblemRepairItem', { workOrderItemProblemId_eq: workOrderItemProblemId }, () => new WorkOrderItemProblemRepairItem());
    }

    getWorkOrderLineOutOfPocketInvoices(workOrderLineId: string) {
        return this.api.getArrayNode(`InvoiceSummary`, { workOrderLineId_eq: workOrderLineId }, () => new InvoiceSummary());
    }

    getJobHasBeenDenied(workOrderId: string) {
        return this.api.getSingleNode(`work-order/has-been-denied/${workOrderId}`);
    }

    updateWorkOrderAssignedTo(contractorId: string, oldEmployeeId: string, newEmployeeId: string) {
        return this.api.patchNode('work-order/update-assigned-to-employee', { contractorId, oldEmployeeId, newEmployeeId });
    }

    updateWorkOrderTechnician(id: string, technicianId: string) {
        return this.api.patchNode(`WorkOrder/${id}`, { technicianId: technicianId });
    }

    updateTuneUpAuthoAmount(id: string, qAndA: string) {

        return this.api.patchDotNet(`workorder/update-authorization-amount`, { id, questions: qAndA });
    }

    getWorkOrderItemProblemTypes(workOrderItemProblemId: string): Promise<WorkOrderItemProblemType[]> {
        return this.api.getArrayDotNet('WorkOrderItemProblemType', { workOrderItemProblemId_eq: workOrderItemProblemId }, () => new WorkOrderItemProblemType());
    }

    sendOnMyWayNotification(id: string, notificationArgs: any) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/OnMyWay`, notificationArgs);
    }

    getRepairItemsByGroup(groupId: string) {
        return this.api.getArrayNode(`RepairItem`, { repairItemGroupId_eq: groupId, orderby: 'name' }, () => new RepairItem());
    }

    getAssociatedLineItems(policyNumber: number, lineItemsIds: string[], lineIds: string[]) {
        const lineS = lineItemsIds.join(',');
        const lineIdss = lineIds.join(',');

        return this.api.getArrayNode(`WorkOrderLineSummary`, { policyNumber_eq: policyNumber, itemId_contains: lineS, id_ncontains: lineIdss, orderby: 'CreatedDate' }, () => new WorkOrderLineSummaryClient());
    }

    getApplianceCoveredTermsFromWorkOrderLine(workOrderLineId: string) {
        return this.api.getArrayNode(`authorization/appliance-coveraged-terms/${workOrderLineId}`, null, () => new SalesItemCoverageApplianceCoverageTerm());
    }

    getPaymentWorkOrder(paymentId: string) {
        return this.api.getSingleNode(`accounting/payment-workorder/${paymentId}`, null, () => new WorkOrderClient());
    }

    getInvoiceWorkOrder(id: string) {
        return this.api.getSingleNode(`accounting/invoice-workorder/${id}`, null, () => new WorkOrderClient());
    }

    getContractorProposedWorkOrderSummary(id: string) {
        return this.api.getSingleNode(`ContractorProposedWorkOrderSummary/${id}`, null, () => new ContractorProposedWorkOrderSummary());
    }

    approveProposedContractorWorkOrder(args: ConvertProposedWorkOrderToWorkOrderArgs) {
        return this.api.postSingleDotNet(`WorkOrder/approve-proposed-contractor-work-order`, args);
    }

    analyzeModelPlate(modelPlateFile: WorkOrderAttachmentModel) {
        return this.api.postSingleNode(`authorization/analyze-model-plate`, modelPlateFile);
    }

    getWorkOrderProblemById(id: string) {
        return this.api.getSingleDotNet(`WorkOrderItemProblem/${id}`);
    }

    acceptAlternateTimeSlotById(workOrderId: string, timeSlotId: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/assign-alternate-time-slot-by-id/${timeSlotId}`, null);
    }

    rejectAlternateTimeSlots(workOrderId: string) {
        return this.api.getSingleDotNet(`WorkOrder/${workOrderId}/reject-alternate-time-slots`);
    }

    getWorkOrderItemCauses(id: string): Promise<WorkOrderItemCause[]> {
        return this.api.getArrayNode('WorkOrderItemCause', { workOrderITemId_eq: id, orderBy: 'sort,name' }, () => new WorkOrderItemCause());
    }

    async getClosedWorkOrdersSummary(startDate: Date, endDate: Date) {
        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const results = await this.api.getArrayNode('ClosedWorkOrdersSummary', { statusDate_gte: startDate, statusDate_lte: endDate }, () => new ClosedWorkOrdersSummary());
        return results;
    }

    findForWorkOrderLineId(workOrderLineId: string) {
        return this.api.getArrayDotNet(`RepairItem/availableForJob`, { jobId: workOrderLineId }, () => new RepairItem());
    }

    processAuthorizationRequest(workOrderLineId: string, args: AuthorizationRequestArgs) {
        return this.api.postSingleDotNet(`WorkOrder/${workOrderLineId}/create-authorization`, args);
    }

    validateAuthorizationRequest(workOrderLineId: string, args: AuthorizationRequestArgs) {
        return this.api.postSingleDotNet(`WorkOrder/${workOrderLineId}/validate-authorization-request`, args);
    }

    requestAlternateTimes(id: string, alternateTimeSlotSuggestion: AlternateTimeSlotSuggestion) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/suggest-alternate-time-slots`, alternateTimeSlotSuggestion);
    }

    requestFollowupAlternateTimes(id: string, alternateTimeSlotSuggestion: AlternateTimeSlotSuggestion) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/suggest-followup-alternate-time-slots`, alternateTimeSlotSuggestion);
    }

    setAcceptedUnscheduled(id: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/update-accepted-unscheduled`, null);
    }



    getProblemsByWorkOrderType(workOrderTypeId: string) {
        return this.api.getArrayDotNet(`WorkOrderItemProblemType`, { workOrderTypeId_eq: workOrderTypeId });
    }

    getWorkOrderItemProblems(id: string): Promise<WorkOrderItemProblem[]> {
        return this.api.getArrayDotNet('WorkOrderItemProblem', { workOrderItemId_eq: id, orderBy: 'sort,name' }, () => new WorkOrderItemProblem());
    }

    getWorkOrderItemTypeById(id: string): Promise<WorkOrderItemType> {
        return this.api.getSingleDotNet(`WorkOrderItemType/${id}`);
    }

    getWorkOrderItemLocationById(id: string): Promise<WorkOrderItemLocation> {
        return this.api.getSingleDotNet(`WorkOrderItemLocation/${id}`);
    }

    getRepairItemsFromProblems(ids: string[], isReplacement: boolean, typeId: string): Promise<RepairItem[]> {
        return this.api.postArrayDotNet(`WorkOrderItem/get-repair-items-from-problems?isReplacement=${isReplacement}&typeId=${typeId}`, ids, () => new RepairItem());
    }

    getProblemCauseById(id: string): Promise<any> {
        return this.api.getSingleDotNet(`ProblemCause/${id}`);
    }

    getWorkOrderItemProblemCause(id: string) {
        return this.api.getSingleDotNet(`WorkOrderItemCause/${id}`);
    }

    getProblemCauses(): Promise<ProblemCause[]> {
        return this.api.getArrayDotNet(`ProblemCause`, { orderby: 'sort' });
    }

    getReplacementReasons(): Promise<ReplacementReason[]> {
        return this.api.getArrayDotNet(`ReplacementReason`, { orderby: 'sort' });
    }

    getHomeInspection(policyId: string) {
        return this.api.getSingleDotNet(`Note/${policyId}/home-inspection`);
    }

    getWorkOrderItemProblemsByIds(ids: string[]): Promise<WorkOrderItemProblem[]> {
        const idString = ids.join(',');
        return this.api.getArrayDotNet(`WorkOrderItemProblem`, { id_contains: idString });
    }

    getWorkOrderItemTypes(id: string): Promise<WorkOrderItemType[]> {
        return this.api.getArrayNode('WorkOrderItemType', { workOrderItemId_eq: id, orderBy: 'sort,name' }, () => new WorkOrderItemType());
    }



    getWorkOrderItemLocations(id: string): Promise<WorkOrderItemLocation[]> {
        return this.api.getArrayDotNet('WorkOrderItemLocation', { workOrderItemId_eq: id, orderBy: 'sort,name' }, () => new WorkOrderItemLocation());
    }


    getItemThumbnailUrl(itemId: string): string {
        return ApiService.endPointDotNet + 'WorkOrderItem/' + itemId + '/Photo';
    }

    getCurrentUserStats(): Promise<WorkOrderStatsModel[]> {
        return this.api.getArrayDotNet('WorkOrder/MyStats', null, () => new WorkOrderStatsModel());
    }

    getClaim(id: string) {
        return this.api.getSingleDotNet(`Claim/${id}`, null, () => new Claim());
    }

    getWorkOrderCountsByType(startDate: Date, endDate: Date) {
        return this.api.getArrayDotNet(`WorkOrder/counts-by-type`, { startDate, endDate });
    }

    updateWorkOrderAssignedEmployee(id: string, employeeId: string) {
        return this.api.patchDotNet(`WorkOrder/${id}`, { AssignedToUserId: employeeId });
    }

    saveWorkOrderLine(workOrderLine: WorkOrderLine) {
        return this.api.postSingleDotNet(`WorkOrderLine`, workOrderLine);
    }

    updateWorkOrderLineItem(workOrderLineId: string, itemId: string) {
        return this.api.patchSingleDotNet(`WorkOrderLine/${workOrderLineId}`, {
            itemId,
            authorizationRequestArgs: null,
        });
    }

    saveAuthorizationLimit(workOrderId: string, workOrderLineId: string, authorizationLimit: number) {
        this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, {
            authorizationLimit
        });
        return this.api.patchSingleDotNet(`WorkOrderLine/${workOrderLineId}`, {
            authorizationLimit
        });
    }

    async saveNewAuthorization(workOrderLineId: string, authorizationArgs: Authorization, repairTypeId: string) {
        await this.api.patchSingleNode(`WorkorderLine/${workOrderLineId}`, {
            authorizationAmount: authorizationArgs.authorizedAmount,
            authorizationLimit: authorizationArgs.authorizedAmount,
        });
        const auth = new WorkOrderLineAuthorization();

        auth.approvedAmount = authorizationArgs.authorizedAmount;
        auth.workOrderLineId = workOrderLineId;
        auth.id = authorizationArgs.id;
        auth.fullArgs = JSON.stringify(authorizationArgs);
        auth.repairTypeId = repairTypeId;


        await this.api.postNode(`WorkOrderLineAuthorization`, auth);
    }

    updateAuthorizationLimit(workOrderLineId: string, limit: number) {
        return this.api.patchNode(`WorkOrderLine/${workOrderLineId}`, {
            authorizationAmount: limit,
            authorizationLimit: limit,
        });
    }

    syncPartsToOrder(workOrderLineId: string, partsToOrder: PartToOrder[]) {

    }

    updateWorkOrderLineStatus(workOrderLineId: string, workOrderStatusId: string) {
        return this.api.patchSingleDotNet(`WorkOrderLine/${workOrderLineId}`, {
            workOrderStatusId
        });
    }

    deleteWorkOrderLine(workOrderLineId: string) {
        return this.api.patchDotNet(`WorkOrderLine/${workOrderLineId}`, { CancelledDate: new Date() });
    }

    unDeleteWorkOrderLine(workOrderLineId: string) {
        return this.api.patchDotNet(`WorkOrderLine/${workOrderLineId}`, { CancelledDate: null });
    }

    updatePolicyOnClaim(claimId: string, policyId: string) {
        return this.api.patchDotNet(`Claim/${claimId}`, {
            policyId
        });
    }

    getWorkOrderLines(workOrderId: string): Promise<WorkOrderLineSummaryClient[]> {
        return this.api.getArrayDotNet('WorkOrderLineSummary', { workORderId_eq: workOrderId, orderby: 'Line' }, () => new WorkOrderLineSummaryClient());
    }

    getWorkOrderLineById(workOrderLineId: string): Promise<WorkOrderLine> {
        return this.api.getSingleDotNet(`WorkOrder/${workOrderLineId}/get-line-detail`, null, () => new WorkOrderLine());
    }

    getWorkOrdersForPolicyAndItem(policyId: string, itemId: string): Promise<WorkOrderLineSummaryClient[]> {
        return this.api.getArrayDotNet(`WorkOrderLineSummary`, { policyId_eq: policyId, itemId_eq: itemId, orderby: 'CreatedDate' }, () => new WorkOrderLineSummaryClient());
    }

    findPrepaidServiceFeeByCode(code: string) {
        return this.api.getSingleDotNet(`PrepaidServiceFee`, { code_eq: code });
    }

    updateWorkOrderItem(id: string, newWorkOrderItemId: string) {
        return this.api.patchDotNet(`WorkOrder/${id}`, { ItemId: newWorkOrderItemId });
    }

    updateWorkOrderContactInfo(id: string, name: string, phone: string, mobile: string, email: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}`, {
            contactName: name,
            contactPhone: phone,
            contactMobile: mobile,
            contactEmail: email
        });
    }

    getSurveySummary(startDate: Date, endDate: Date, areaIds: string[], contractorIds: string[], tradeIds: string[], itemIds: string[]) {
        return this.api.getArrayDotNet(`WorkOrderSummary/survey-summary`, { startDate, endDate, areaIds, contractorIds, tradeIds, itemIds }, () => new ContractorSurveyResult());
    }

    getSurveyDetails(startDate: Date, endDate: Date, areaIds: string[], contractorIds: string[], tradeIds: string[], itemIds: string[], contractorId: string) {
        return this.api.getArrayDotNet(`WorkOrderSummary/survey-details`, { startDate, endDate, areaIds, contractorIds, tradeIds, itemIds, contractorId }, () => new WorkOrderSummaryClient());

    }

    getWorkOrderServiceFeeCancellationInfo(id: string) {
        return this.api.getSingleDotNet(`WorkOrder/${id}/cancel-service-fee-info`, null, () => new WorkOrderServiceFeeCancellationInfo());
    }

    processWorkOrderServiceFeeCancellationInfo(id: string, args: WorkOrderServiceFeeCancellationInfo) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/process-service-fee-cancellation`, args);
    }

    async getItemHasTimeSlots(itemId: string): Promise<boolean> {
        const item = await this.api.getSingleDotNet(`WorkOrderItem/${itemId}`, { select: 'allowTimeSlotSelection' });
        return item.allowTimeSlotSelection;
    }

    getContractorInvoicePaymentSummaryByIdNoAuth(contractorInvoicePaymentId: string): Promise<ContractorInvoicePaymentSummary> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/contractor-invoice-payment-summary/${contractorInvoicePaymentId}`, null, () => new ContractorInvoicePaymentSummary());
    }

    getWorkOrderSummaryCached() {
        return this.api.getArrayDotNet(`WorkOrderSummaryCached`);
    }

    // getDynamicServiceFee(policyId: string, workOrderItemId: string, questionnaire: string) {
    //     return this.api.postSingle(`WorkOrder/get-dynamic-service-fee`, {
    //         PolicyId: policyId,
    //         WorkOrderItemId: workOrderItemId,
    //         Questionnaire: questionnaire,
    //     });
    // }

    getDynamicServiceFeeV2(policyId: string, workOrderLines: WorkOrderLineSummaryClient[], source: string) {
        return this.api.postSingleDotNet(`WorkOrder/get-dynamic-service-fee-v2`, {
            PolicyId: policyId,
            WorkOrderLines: workOrderLines,
            Source: source,
        });
    }

    getWorkOrderStatusHistoryNoAuth(workOrderId: string): Promise<WorkOrderStatusChange[]> {
        return this.api.getArrayDotNet(
            `AnonymousClaimDetail/get-work-order-status-history/${workOrderId}`, null,
            () => new WorkOrderStatusChange());
    }

    getWorkOrderNotesNoAuth(workOrderId: string): Promise<NoteClient[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/${workOrderId}/notes`, null, () => new NoteClient());
    }

    saveWorkOrderNoteNoAuth(note: NoteClient): Promise<any> {
        return this.api.postSingleDotNet(`AnonymousClaimDetail/save-note`, note);
    }

    async getPreviousJobsByItem(policyId: string, itemId: string): Promise<WhiteGloveJobItem[]> {
        const results = await this.api.getArrayNode(`work-order/get-previous-jobs/${policyId}/${itemId}`);
        return results;
    }

    getJobsCompleted(accountExecutiveId: string, startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`work-order/get-jobs-completed`, { accountExecutiveId: accountExecutiveId, startDate: startDate, endDate: endDate });
    }

    async getWorkOrderSummaryNoAuth(workOrderId: string, contractorId: string): Promise<WorkOrderSummaryClient> {
        const result = await this.api.getSingleDotNet<WorkOrderSummaryClient>(`AnonymousClaimDetail/work-order-summary/${workOrderId}/${contractorId}`, null, () => new WorkOrderSummaryClient());
        if (result) {
            result.lines = this.api.processSubResult(result.lines, () => new WorkOrderLineSummaryClient(), ResultExtractionType.Array);
        }
        return result;
    }

    async getWorkOrderSummaryById(workOrderId: string): Promise<WorkOrderSummaryClient> {
        //
        const result = await this.api.getSingleDotNet<WorkOrderSummaryClient>(`AnonymousClaimDetail/work-order-summary-by-id/${workOrderId}`, null, () => new WorkOrderSummaryClient());
        result.lines = this.api.processSubResult(result.lines, () => new WorkOrderLineSummaryClient(), ResultExtractionType.Array);
        return result;
    }


    getWorkOrderAppointmentTimeSlotsNoAuth(workOrderId: string, type: string): Promise<AppointmentTimeSlot[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/work-order-appointment-time-slots/${workOrderId}`, { type }, () => new AppointmentTimeSlot());
    }

    getUnacceptedFollowUpAppointmentCount(workOrderId: string): Promise<number> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/${workOrderId}/unaccepted-follow-up-jobs`);
    }

    getAddressNoAuth(addressId: string): Promise<Address> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/get-address/${addressId}`, null, () => new Address());
    }

    setWorkOrderAppointmentDateNoAuth(workOrderId: string, scheduledDate: Date, startWindow: string, endWindow: string): Promise<any> {
        return this.api.patchDotNet(`AnonymousClaimDetail/set-work-order-appointment-date/${workOrderId}?scheduledDate=${UtilitiesService.dayBegin(scheduledDate).toISOString()}&startWindow=${startWindow}&endWindow=${endWindow}`, null);
    }

    getCashOutSummary(cashOutId: string) {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/get-cash-out-summary/${cashOutId}`);
    }

    getCashOutAppliedLines(cashOutId: string) {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/get-cash-out-applied-lines/${cashOutId}`);
    }

    async saveAuthorizationAttachmentsNoAuth(note: any, attachments: NoteAttachment[]): Promise<any> {
        note.id = UtilitiesService.newid();
        await this.api.postVoidDotNet(`AnonymousClaimDetail/save-note`, note);
        const promises = [];
        attachments.forEach(attachment => {
            attachment.noteId = note.id;
            promises.push(this.api.postVoidDotNet(`Note/Attachment/${note.id}`, attachment));
        });
        await Promise.all(promises);
    }

    getAssignedEmployees(workOrderId: string) {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/get-assigned-employees/${workOrderId}`, null, () => new Entity());
    }

    getContractorTechniciansNoAuth(contractorId: string) {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/get-contractor-technicians/${contractorId}`, null, () => new Entity());
    }

    getWorkOrderItemDetailNoAuth(workOrderLineId: string) {
        return this.api.getSingleDotNet("PolicyServiceOffering/work-order-line/" + workOrderLineId);
    }


    getWorkOrderLineDetailNoAuth(workOrderLineId: string) {
        return this.api.getSingleDotNet(`PolicyServiceOffering/work-order-line/${workOrderLineId}`);
    }

    getWorkOrderStatusChangeHistoryByLineId(workOrderLineId: string): Promise<WorkOrderStatusChange[]> {
        return this.api.getArrayDotNet(`WorkOrderStatusChangeSummary`, { workOrderLineId_eq: workOrderLineId, orderby: 'CreatedDate desc' });
    }

    completeWorkOrderNoAuth(workOrderId: string, resolution: string, dateCompleted: Date): Promise<any> {
        return this.api.patchDotNet(`AnonymousClaimDetail/complete-work-order/${workOrderId}`, {
            status: 'Complete',
            resolution,
            dateCompleted,
        });
    }

    getAuthorizationLinesNoAuth(workOrderId: string): Promise<AuthorizationLine[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/authorization-lines/${workOrderId}`, null, () => new AuthorizationLine());
    }

    updateWorkOrderStatusNoAuth(workOrderId: string, status: string): Promise<any> {
        return this.api.patchDotNet(`AnonymousClaimDetail/update-work-order-status/${workOrderId}?status=${status}`, null);
    }

    updateWorkOrderTechnicianNoAuth(workOrderId: string, technicianId: string) {
        return this.api.patchDotNet(`AnonymousClaimDetail/update-work-order-technician/${workOrderId}/${technicianId}`, null);
    }

    acceptTimeSlotNoAuth(workOrderId: string, timeSlotId: string, contractorId: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/assign-time-slot/${timeSlotId}/${contractorId}`, null);
    }

    acceptFollowupTimeSlotNoAuth(workOrderId: string, timeSlotId: string, contractorId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}/assign-follow-up-time-slot/${timeSlotId}/${contractorId}`, null);
    }



    setWorkOrderCompleteDateNoAuth(id: string, completeDate: Date) {
        return this.api.patchSingleDotNet(`AnonymousClaimDetail/set-work-order-complete-date/${id}?completeDate=${completeDate.toISOString()}`, null);
    }

    getWorkOrderContractorInvoiceNoAuth(workOrderId: string) {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/contractor-invoice/${workOrderId}`,
            null
            , () => new ContractorInvoice());
    }

    getPurchaseOrderTracking(purchaseOrderId: string) {
        return this.api.getSingleDotNet(`purchasing/PurchaseOrder/${purchaseOrderId}/track`);
    }

    getPurchaseOrderItemsForWorkOrder(workOrderId: string) {
        return this.api.getArrayNode(`PurchaseOrderItemSummary`, { workOrderId_eq: workOrderId }, () => new PurchaseOrderItemSummary());
    }

    getWorkOrderAttachmentsNoAuth(workOrderId: string): Promise<WorkOrderAttachmentModel[]> {
        return this.api.getArrayDotNet(`WorkOrder/${workOrderId}/attachments`, null, () => new WorkOrderAttachmentModel());
    }


    reassignWorkOrderNoAuth(workOrderId: string): Promise<boolean> {
        return this.api.patchSingleDotNet(`workorder/${workOrderId}/auto-reassign`, null);
    }

    getAssignedAppointmentsForDayNoAuth(day: Date, contractorId: string) {
        const startDate = UtilitiesService.dayBegin(day);
        const endDate = UtilitiesService.dayEnd(day);
        return this.api.getArrayDotNet(`AnonymousClaimDetail/assigned-appointments/${contractorId}/${startDate.toISOString()}/${endDate.toISOString()}`, null, () => new WorkOrderSummaryClient());
    }

    getWorkOrderContractorInvoicesNoAuth(workOrderId: string): Promise<ContractorInvoiceSummaryClient[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/contractor-invoices/${workOrderId}`, null, () => new ContractorInvoiceSummaryClient());
    }

    getWorkOrderLinesNoAuth(workOrderId: string): Promise<WorkOrderLineSummaryClient[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/${workOrderId}/line-items`, null, () => new WorkOrderLineSummaryClient());
    }

    getWorkOrderLineStatusChangesNoAuth(workOrderId: string): Promise<WorkOrderStatusChange[]> {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/${workOrderId}/line-item-status-change-summary`)
    }

    getWorkOrderStatusesNoAuth() {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/get-work-order-statuses`);
    }

    updateWorkOrderLineStatusNoAuth(id: string, statusId: string) {
        // /{id}/{statusId}
        return this.api.patchSingleDotNet(`AnonymousClaimDetail/update-work-order-line-status/${id}/${statusId}`, null);
    }

    getContractorPortalEnabled(): Promise<boolean> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/contractor-portal-enabled`);
    }

    getOnlineAuthorizationEnabled(): Promise<boolean> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/online-autho-enabled`);
    }

    async getContractorOnWorkOrder(claimId: string, contractorId: string): Promise<WorkOrderClient> {
        return this.api.getSingleNode(`WorkOrder`, { claimId_eq: claimId, contractorId_eq: contractorId }, () => new WorkOrderClient());
    }

    async editContractorOnWorkOrder(workOrderId: string, contractorId: string) {
        const contractor: Entity = await this.api.getSingleDotNet(`Entity/${contractorId}`);
        if (!contractor) return null;

        if (contractor.assignedEmployeeId) {
            return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, {
                contractorId, sendEmail: contractor.email, sendViaEmail: contractor.contactViaEmail,
                sendViaText: contractor.contactViaSMSText, dispatchExportDate: null,
                assignedToUserId: contractor.assignedEmployeeId
            });
        }

        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, {
            contractorId, sendEmail: contractor.email, sendViaEmail: contractor.contactViaEmail,
            sendViaText: contractor.contactViaSMSText, dispatchExportDate: null
        });
    }

    saveInvoiceNoAuth(invoice: ContractorInvoice) {
        if (!invoice.id) {
            invoice.id = UtilitiesService.newid();
        }
        if (!invoice.receivedDate) {
            invoice.receivedDate = new Date();
        }
        invoice.approvedDate = null;

        return this.api.postVoidDotNet('AnonymousClaimDetail/save-invoice', invoice);
    }

    getWorkOrderContractorInvoices(workOrderId: string) {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/get-job-contractor-invoices/${workOrderId}`);
    }

    approveCashOutEmail(id: string) {
        return this.api.patchSingleDotNet(`AnonymousClaimDetail/approve-cash-out-email/${id}`, null);
    }

    approveCashOutMail(id: string) {
        return this.api.patchSingleDotNet(`AnonymousClaimDetail/approve-cash-out-mail/${id}`, null);
    }

    approveCashOutPayPal(id: string) {
        return this.api.patchSingleNode(`cash-out/approve-paypal-cashout/${id}`, null);
    }

    approveCashOutVenmo(id: string) {
        return this.api.patchSingleNode(`cash-out/approve-venmo-cashout/${id}`, null);
    }

    executeRESTEndpoint(url: string, body: string, restEndpointAction: string) {
        let urlCopy = url.slice();
        if (urlCopy.indexOf('http:') > -1 || urlCopy.indexOf('https:') > -1) {
            urlCopy = `REST?url=${encodeURI(url)}`;
        }

        if (restEndpointAction === 'GET') {
            return this.api.getArrayDotNet(urlCopy, body);
        } else if (restEndpointAction === 'POST') {
            return this.api.postSingleDotNet(urlCopy, body);
        } else if (restEndpointAction === 'PATCH') {
            return this.api.patchDotNet(urlCopy, body);
        } else if (restEndpointAction === 'DELETE') {
            return this.api.deleteDotNet(urlCopy);
        }
        throw new Error(`RESTEndpointAction ${restEndpointAction} not implemented.`);
    }

    getUnusedPrepaidServiceFeesForPolicy(policyId: string): Promise<PrepaidServiceFee[]> {
        return this.api.getArrayDotNet(`PrepaidServiceFee`, { policyId_eq: policyId, redeemedDate_eq: '{{null}}', orderby: 'CreatedDate' }, () => new PrepaidServiceFee());
    }

    getServicePerformedPostCardsToPrint(startDate: Date, endDate: Date, selectedAreaIds: string[]): Promise<string[]> {
        return this.api.getArrayDotNet(`ServicePerformedPostCardToPrint`, {
            createdDate_gte: startDate, createdDate_lte: endDate, select: 'id',
            areaId_contains: selectedAreaIds
        });
    }

    generateServicePerformedPostCardBatch(ids: string[]) {
        return this.api.postSingleDotNet(`workorder/batch-service-performed-post-card-printing`, ids);
    }

    completeServicePerformedPostCardBatchPrint(batchId: string) {
        return this.api.patchSingleDotNet(`workorder/complete-service-performed-card-print-batch/${batchId}`, null);
    }


    getAppointmentTimeSlots(workOrderItemId: string): Promise<AppointmentTimeSlot[]> {
        return this.api.getArrayDotNet('AppointmentTimeSlot', { workOrderItemId }, () => new AppointmentTimeSlot());
    }

    getWorkOrderTag(tagId: string): Promise<Tag> {
        return this.api.getSingleNode(`Tag`, { id_eq: tagId });
    }

    getWorkOrderTags(workOrderId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`WorkOrder/${workOrderId}/tags`);
    }

    replaceWorkOrderTags(workOrderId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/replace-tags`, tags);
    }

    updateContractorAndAssignedEmployeeOnWorkOrder(workOrderId: string, contractorId: string, assignedEmployeeId: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, { contractorId: contractorId, assignedToUserId: assignedEmployeeId });
    }

    updateContractorOnWorkOrder(workOrderId: string, newContractorId: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, { contractorId: newContractorId });
    }

    addWorkOrderTags(workOrderId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/add-tags`, tags);
    }

    removeWorkOrderTags(workOrderId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/remove-tags`, tags);
    }

    getWorkOrderPreferredTime(workOrderId: string): Promise<WorkOrderPreferredTimeSummary[]> {
        return this.api.getArrayNode(`WorkOrderPreferredTimeSummary`, { workOrderId_eq: workOrderId }, () => new WorkOrderPreferredTimeSummary());
    }

    newPreferredTimeSlots(workOrderId: string, timeSlots: WorkOrderPreferredTime[]) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}/new-preferred-times`, timeSlots);
    }

    getExclusions(policyId: string, workOrderItemId: string): Promise<PolicyExclusion[]> {
        return this.api.getArrayDotNet(`PolicyExclusion/`, {
            policyId_eq: policyId,
            workOrderItemId_eq: workOrderItemId,
        });
    }

    saveClaim(claim: Claim): Promise<string> {
        return this.api.postIdDotNet(
            'Claim', claim);
    }

    savePrepaidServiceFee(prepaidServiceFee: PrepaidServiceFee) {
        return this.api.postSingleDotNet(`PrepaidServiceFee`, prepaidServiceFee);
    }

    takeServiceFeePayment(policyId: string, serviceFee: number, stripeCardId: string, holderId: string, billingCustomerId: string, saveCardForLater: boolean, idempotencyKey: string) {
        return this.api.postSingleDotNet(`claim/take-serivce-fee-payment`, { policyId, serviceFee, stripeCardId, holderId, billingCustomerId, removeCardAfterProcessing: !saveCardForLater, idempotencyKey });
    }

    saveClaimWithErrorResponse(claim: Claim, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.api.postIdDotNet(`claim/submit-with-error-response`, claim, null, false, errorHandler);
    }

    setWorkOrderStatus(workOrderId: string, statusId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}`, { WorkOrderStatusId: statusId });
    }

    setWorkOrderLineStatus(workOrderLineId: string, statusId: string) {
        return this.api.patchDotNet(`WorkOrderLine/${workOrderLineId}`, { WorkOrderStatusId: statusId });
    }

    saveWorkOrder(workOrder: WorkOrderClient): Promise<string> {
        return this.api.postIdDotNet(
            'WorkOrder', workOrder);
    }

    getContractorSelection(policyId: string, workOrderItemId: string, brandName: string, qAndA: string): Promise<ContractorSelection[]> {
        return this.api.postArrayDotNet('WorkOrder/get-contractor-selection', { policyId, workOrderItemId, brandName, qAndA, brandId: null }, null, () => new ContractorSelection());
    }


    getContractorSelectionV2(policyId: string, workOrderLines: WorkOrderLineSummaryClient[]): Promise<ContractorSelection[]> {
        return this.api.postArrayDotNet('WorkOrder/get-contractor-selection-v2', { policyId, workOrderLines }, null, () => new ContractorSelection());
    }

    async getWorkOrderSummary(workOrderId: string): Promise<WorkOrderSummaryClient> {

        const result = await this.api.getSingleDotNet('WorkOrderSummary/' + workOrderId, null, () => new WorkOrderSummaryClient());
        if (result) {
            result.lines = this.api.processSubResult(result.lines, () => new WorkOrderLineSummaryClient(), ResultExtractionType.Array);
        }

        return result;
    }

    getWorkOrder(workOrderId: string): Promise<WorkOrderClient> {
        return this.api.getSingleDotNet('WorkOrder/' + workOrderId, null, () => new WorkOrderClient());
    }

    getWorkOrderByNumber(workOrderNumber: number) {
        return this.api.getSingleDotNet(`WorkOrder`, { select: 'id,number', number_eq: workOrderNumber });
    }

    getWorkOrderItemList(): Promise<any> {
        return this.api.getArrayDotNet('WorkOrderItem/select?properties=id,name&orderby=name');
    }

    testAuthorizationRule(workOrderId: string, rule: AuthorizationRule, returnFullPacket: boolean) {
        return this.api.postSingleNode(`AuthorizationRule/${workOrderId}/TestRule?returnFullPacket=${returnFullPacket}`, rule);
    }

    getMultiItemTrade(policyId: string, tradeId: string): Promise<WorkOrderSummaryClient[]> {
        const fourHoursAgo = new Date();
        fourHoursAgo.setHours(fourHoursAgo.getHours() - 4);
        return this.api.getArrayDotNet('WorkOrderSummary/select', {
            policyId_eq: policyId,
            tradeId_eq: tradeId,
            createdDate_gt: fourHoursAgo,
            take: 1,
            properties: 'contractorId,number,claimId,contractorName'
        });
    }

    getSalesItemCoverageForWorkOrder(workOrderId: string): Promise<SalesItemCoverageClient> {
        return this.api.getSingleDotNet(
            'WorkOrder/' + workOrderId + '/CoverageLanguage', null,
            () => new SalesItemCoverageClient());
    }

    getSalesItemCoverageForWorkOrderItemFees(policyId: string, workOrderItemId: string): Promise<SalesItemCoverageWorkOrderItemFees[]> {
        return this.api.getArrayNode(`work-order/get-sales-coverage-item-work-order-item-fees`, {
            policyId: policyId,
            workOrderItemId: workOrderItemId
        },
            () => new SalesItemCoverageWorkOrderItemFees());
    }

    getWorkOrderStatusHistory(workOrderId: string): Promise<WorkOrderStatusChange[]> {
        return this.api.getArrayDotNet(
            'WorkOrderStatusChangeSummary',
            { workOrderId_eq: workOrderId, orderby: 'createdDate descending' },
            () => new WorkOrderStatusChange());
    }

    getWorkOrderAmazonContacts(workOrderId: string): Promise<AmazonContact[]> {
        return this.api.getArrayDotNet('AmazonContact', { workOrderId_eq: workOrderId, orderby: 'createdDate descending' },
            () => new AmazonContact());
    }

    async setWorkOrderAppointmentDate(workOrderId: string, scheduledDate: Date, startWindow: string, endWindow: string): Promise<any> {

        await this.api.patchDotNet(
            'WorkOrder/' + workOrderId, {
            ScheduledDate: UtilitiesService.dayBegin(scheduledDate),
            Status: 'Scheduled',
            ScheduledStartWindow: startWindow,
            ScheduledEndWindow: endWindow,
            WorkOrderStatusId: 'B849F529-6C42-4BAB-9C6E-039BA5D123DC'
        });

        const lines = await this.api.getArrayDotNet(`WorkOrderLine`, { workOrderId_eq: workOrderId }, () => new WorkOrderLine());
        for (const line of lines) {
            await this.api.patchDotNet(`WorkOrderLine/${line.id}`, { workOrderStatusId: 'B849F529-6C42-4BAB-9C6E-039BA5D123DC' });
        }
        return true;
    }

    getOtherWorkOrderLines(policyNumber: number, itemId: string, startDate: Date) {
        return this.api.getArrayDotNet(`WorkOrderLineSummary`, { createdDate_gte: startDate, itemId_eq: itemId, policyNumber_eq: policyNumber }, () => new WorkOrderLineSummaryClient());
    }

    getWorkOrderSurvey(workOrderId: string): Promise<WorkOrderSurveySummary[]> {
        return this.api.getArrayDotNet(
            'WorkOrderSurveySummary', { workOrderId_eq: workOrderId },
            () => new WorkOrderSurveySummary());
    }
    getAnonymousWorkOrderSurvey(workOrderId: string): Promise<WorkOrderSurveySummary[]> {
        return this.api.getArrayDotNet(
            `AnonymousClaimDetail/survey/${workOrderId}`, null,
            () => new WorkOrderSurveySummary());
    }

    getAnonymousFirstEffectiveDate(policyId: string) {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/policy-first-effective/${policyId}`);
    }

    submitRecall(workOrderId: string, recallInfo: RecallModel): Promise<WorkOrderClient> {
        return this.api.postSingleDotNet('WorkOrder/' + workOrderId + '/Recall', recallInfo);
    }

    getAuthorizationForm(workOrderLineId: string): Promise<any> {
        return this.api.getSingleDotNet('WorkOrder/' + workOrderLineId + '/AuthorizationForm');
    }



    async getLaborTotal(workOrderId: string): Promise<number> {
        return await this.api.getSingleDotNet('WorkOrder', { select: 'laborTotal', id_eq: workOrderId });
    }

    async setLaborTotal(workOrderId: string, laborTotal: number) {
        return this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, { laborTotal: laborTotal });
    }

    getTechnicianContactInformation(workOrderId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getSingleDotNet('WorkOrder/select', {
                properties: 'TechnicianContactInfo',
                id_eq: workOrderId
            }).then(results => {
                if (results && results.length) {
                    const contactInfoString = results;
                    if (contactInfoString) {

                        const deserialized = JSON.parse(contactInfoString);
                        const techContact = this.api.convertToType(deserialized, () => new TechnicianContact());

                        resolve(techContact);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            });
        });
    }

    getAuthorizationTaskForWorkOrder(workOrderId: string): Promise<Task> {
        return this.api.getSingleDotNet('Task', {
            workorderId_eq: workOrderId,
            taskQueueId_eq: '7F01D5CC-58BC-4CB1-BCBF-2067DC0EA3E9',
            completedDate_eq: '{{null}}'
        });
    }

    getWorkOrderNotes(workOrderId: string): Promise<NoteClient[]> {
        return this.api.getArrayDotNet('Note', {
            workOrderId_eq: workOrderId,
            orderBy: 'createdDate descending',
        }, () => new NoteClient());
    }

    saveAuthorizationForm(workOrderLineId: string, authorizationForm: any) {
        const copyOfForm = JSON.parse(JSON.stringify(authorizationForm));
        for (const item of copyOfForm) {
            delete item.childLines;
        }
        return this.api.postVoidDotNet('WorkOrder/' + workOrderLineId + '/AuthorizationForm',
            {
                json: JSON.stringify(copyOfForm),
            });
    }

    async transferClaims(fromPolicyId: string, toPolicyId: string) {
        const allPromises: Promise<string>[] = [];
        const claims = await this.api.getArrayDotNet(`Claim`, { policyId_eq: fromPolicyId }, () => new Claim());
        claims.forEach(claim => {
            claim.policyId = toPolicyId;
            allPromises.push(this.saveClaim(claim));
        });

        return await Promise.all(allPromises);
    }

    async saveAuthorizationApproved(workOrderLineId: string, authorizationAmount: number) {
        const now = new Date();
        return await this.api.patchDotNet('WorkOrderLine/' + workOrderLineId, {
            authorizationAmount,
            authorizationApprovedDate: now,
            authorizationDeniedDate: null,
            authorizationDeniedForm: null,
        });
    }

    async saveAuthorizationDenied(workOrderLineId: string, authorizationAmount: number, authorizationDeniedFormJson: string) {

        const now = new Date();
        return await this.api.patchDotNet('WorkOrderLine/' + workOrderLineId, {
            authorizationAmount,
            authorizationDeniedForm: authorizationDeniedFormJson,
            authorizationDeniedDate: now,
            authorizationApprovedDate: null,
        });
    }

    runAuthorizationRules(workOrderId: string): Promise<any> {
        return this.api.getSingleDotNet(
            'WorkOrder/' + workOrderId + '/TestAuthorizationRules');
    }

    resendWorkOrderConfirmation(workOrderId: string, email: string) {
        return this.api.getSingleDotNet(`WorkOrder/${workOrderId}/HtmlConfirmation/Resend/${email}`);
    }

    async transferWorkOrder(originalWorkOrderId: string, workOrder: WorkOrderClient, cancelOriginal: boolean, lineIdsToDelete: string[], transferReasonId: string, originalDispatchExportDate: Date) {
        if (cancelOriginal) {
            await this.api.patchDotNet(
                'WorkOrder/' + originalWorkOrderId,
                { CancelledDate: new Date(), transferReasonId });

            if (originalDispatchExportDate) {
                await this.api.getSingleNode(`dispatch/cogent-transferred/${originalWorkOrderId}`);
            }
        } else {
            for (const lineId of lineIdsToDelete) {
                await this.api.deleteDotNet(`WorkOrderLine/${lineId}`);
            }
        }
        return await this.api.postSingleDotNet('WorkOrder', workOrder);
    }


    create2ndOpinion(workOrder: WorkOrderClient): Promise<any> {
        return this.api.postSingleDotNet('WorkOrder', workOrder);
    }

    cancelWorkOrder(workOrderId: string): Promise<any> {
        return this.api.deleteDotNet(
            'WorkOrder/' + workOrderId + '/Cancel');
    }

    unCancelWorkOrder(workOrderId: string): Promise<any> {
        return this.api.patchDotNet(
            'WorkOrder/' + workOrderId, { CancelledDate: null });
    }

    resendWorkOrderEmail(workOrderId: string, email: string): Promise<any> {
        return this.api.getSingleDotNet(
            'WorkOrder/' + workOrderId + '/Html/Resend/' + email);
    }

    resendWorkorderSMS(workOrderId: string, mobileNumber: string): Promise<any> {
        return this.api.getSingleDotNet(
            'WorkOrder/' + workOrderId + '/SMS/Resend/' + mobileNumber);
    }

    assignTaskToUser(taskId: string): Promise<any> {
        return this.api.patchDotNet(
            'task/' + taskId, { ownerId: '{{currentUser}}' });
    }

    getWorkOrderReport(
        startDate: Date, endDate: Date, showJustMine: boolean, regions: CompanyRegion[],
        trades: Trade[], statuses: WorkOrderStatus[], callTypes: string[],
        employees: SearchResultModel[], contractors: SearchResultModel[],
        selectedItems: WorkOrderItemClient[], regionsExcluded: CompanyRegion[],
        tradesExcluded: Trade[], statusesExcluded: WorkOrderStatus[],
        callTypesExcluded: string[], employeesExcluded: SearchResultModel[],
        contractorsExcluded: SearchResultModel[], selectedItemsExcluded: WorkOrderItemClient[],
        selectedSLA: string[], selectedSLAExcluded: string[]
    ): Promise<any> {

        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const params = {
            CreatedDate_gte: startDate,
            CreatedDate_lte: endDate,
            properties:
                'id,number,createdDate,itemName,propertyAddress,type,status,tradeName,contractorName,createdByName,slaStatus,surveyScore,totalCashOuts,totalContractorInvoices,totalPurchaseOrders,propertyCity'
        } as any;
        if (showJustMine) {
            params.CreatedById_eq = '{{currentUser}}';
        }

        if (regions && regions.length > 0) {
            let regionsString = '';
            regions.forEach(region => {
                if (regionsString) {
                    regionsString += ',';
                }
                regionsString += region.id;
            });
            params.RegionId_contains = regionsString;
        }

        if (trades && trades.length > 0) {
            let tradesString = '';
            trades.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_contains = tradesString;
        }
        if (employees && employees.length > 0) {
            let qString = '';
            employees.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.CreatedById_contains = qString;
        }
        if (contractors && contractors.length > 0) {
            let qString = '';
            contractors.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.ContractorId_contains = qString;
        }
        if (selectedItems && selectedItems.length > 0) {
            let qString = '';
            selectedItems.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.itemIds = qString;
        }
        if (statuses && statuses.length > 0) {
            let qString = '';
            statuses.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderStatusId_contains = qString;
        }
        if (selectedSLA && selectedSLA.length > 0) {
            let qString = '';
            selectedSLA.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.slaStatus_contains = qString;
        }

        if (selectedSLAExcluded && selectedSLAExcluded.length > 0) {
            let qString = '';
            selectedSLAExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.slaStatus_ncontains = qString;
        }
        if (callTypes && callTypes.length > 0) {
            let qString = '';
            callTypes.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.Type_contains = qString;
        }

        // Excluded
        if (regionsExcluded && regionsExcluded.length > 0) {
            let regionsString = '';
            regionsExcluded.forEach(region => {
                if (regionsString) {
                    regionsString += ',';
                }
                regionsString += region.id;
            });
            params.RegionId_ncontains = regionsString;
        }

        if (tradesExcluded && tradesExcluded.length > 0) {
            let tradesString = '';
            tradesExcluded.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_ncontains = tradesString;
        }
        if (employeesExcluded && employeesExcluded.length > 0) {
            let qString = '';
            employeesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.CreatedById_ncontains = qString;
        }
        if (contractorsExcluded && contractorsExcluded.length > 0) {
            let qString = '';
            contractorsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.ContractorId_ncontains = qString;
        }
        if (selectedItemsExcluded && selectedItemsExcluded.length > 0) {
            let qString = '';
            selectedItemsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.excludedItemIds = qString;
        }
        if (statusesExcluded && statusesExcluded.length > 0) {
            let qString = '';
            statusesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderStatusId_ncontains = qString;
        }
        if (callTypesExcluded && callTypesExcluded.length > 0) {
            let qString = '';
            callTypesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.Type_ncontains = qString;
        }


        return this.api.getArrayDotNet(
            'WorkOrderSummary/queue', params, () => new WorkOrderSummaryClient());
    }

    convertToFirstCall(id: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/convert-to-first-call`, {});
    }
}