export class SearchResultModel {
    constructor(public name = '',
        public id = '',
        public recordType = '',
        public recordSubType = '',
        public email = '',
        public address1 = '',
        public city = '',
        public state = '',
        public postalCode = '',
        public inative = '',
        public homeNumber = '',
        public mobileNumber = '',
        public number = 0,
        public lastModifiedDate = new Date(),
        public deletedDate?: Date,
        public inactiveDate?: Date,
        public additionalInfo = null) { }

    workNumber: string;
    key1: string;
    key2: string;
    key3: string;
    status: string;

    // client-side only
    selected = false;
    selecting = false;

    get line3() {

        if(this.recordType === 'MaintenanceServiceCustomerProperty') {
            return this.additionalInfo;
        }
        return this.status;
    }

    get url(): string {
        if (this.recordType === 'Policy') {
            return '/subscription/' + this.id;
        }
        if (this.recordType === 'Entity' && this.recordSubType === 'Customer') {
            return '/subscription/view-customer/' + this.id;
        }
        if (this.recordType === 'Entity' && this.recordSubType === 'Contractor') {
            return '/service-pro-relations/' + this.id + '/overview';
        }
        if(this.recordType === 'Entity' && this.recordSubType === 'Vendor') {
            return '/entity/view-vendor/' + this.id;
        }
        const realEstateTypes = ['Agent', 'Branch', 'ClosingOfficer', 'Real_Estate_Company', 'Title_Company', 'Transaction_Coordinator',
            'Mortgage_Company', 'Loan_Officer', 'Insurance_Company', 'Insurance_Branch', 'Insurance_Agent'];
        if (this.recordType === 'Entity' && realEstateTypes.indexOf(this.recordSubType) > -1) {
            return `/entity/real-estate-edit/${this.id}`;
        }
        if (this.recordType === 'Entity') {
            return '/entity/edit/' + this.id;
        }
        if (this.recordType === 'WorkOrder') {
            return '/service/claim-detail/' + this.id;
        }
        if (this.recordType === 'Task') {
            return `/users/tasks/${this.id}`;
        }
        if (this.recordType === 'PurchaseOrder') {
            return `purchasing/purchase-order/${this.key1}/${this.id}`;
        }
        if (this.recordType === 'MenuItem') {
            return this.additionalInfo;
        }
        if (this.recordType === 'ContractorInvoice') {
            return `accounting/service-pro/invoice/${this.key1}/${this.id}`;
        }
        if (this.recordType === 'CashOut') {
            return `service/cash-out/${this.key1}/${this.id}`;
        }
        if (this.recordType === 'Invoice') {
            return `/subscription/${this.key1}`;
        }
        if(this.recordType === 'Note' && this.recordSubType === 'PurchaseOrder') {
            return `/purchasing/purchase-order/${this.key2}/${this.key3}`;
        }
        if(this.recordType === 'Note' && this.recordSubType === 'Task') {
            return `/users/tasks/${this.key3}`;
        }
        if(this.recordType === 'Note' && this.recordSubType === 'WorkOrder') {
            return `/service/claim-detail/${this.key2}`;
        }
        if(this.recordType === 'Note' && this.recordSubType === 'Policy') {
            return `subscription/${this.key1}`;
        }
        if(this.recordType === 'MaintenanceServiceCustomerProperty') {
            return `subscription/maint-service-customer-property/${this.id}`;
        }
    }

    get icon(): string {
        if (this.recordType === 'Policy') {
            return 'folder';
        }
        if (this.recordType === 'Entity') {
            return 'person';
        }
        if (this.recordType === 'WorkOrder') {
            return 'build';
        }
        if (this.recordType === 'MenuItem') {
            return this.recordSubType;
        }
        if (this.recordType === 'Task') {
            return 'playlist_add_check';
        }
        if (this.recordType === 'PurchaseOrder') {
            return 'store';
        }
        if (this.recordType === 'ContractorInvoice') {
            return 'monetization_on';
        }
        if (this.recordType === 'CashOut') {
            return 'compare_arrows';
        }
        if (this.recordType === 'Invoice') {
            return 'description';
        }
        if(this.recordType === 'Note') {
            return 'edit_note';
        }

        if (this.recordType === 'Easter Egg') {
            return 'military_tech';
        }
    }

    get title(): string {
        if (this.recordType === 'Policy') {
            return 'Subscription # ' + this.number + ' - ' + this.name;
        }

        if (this.recordType === 'WorkOrder') {
            return 'SR# ' + this.number + ' - ' + this.additionalInfo;
        }
        if (this.recordType === 'Task') {
            return this.name;
        }
        if (this.recordType === 'PurchaseOrder') {
            return `PO# ${this.number} - ${this.name}`;
        }
        if (this.recordType === 'ContractorInvoice') {
            return `INV# ${this.number} - ${this.name}`;
        }
        if (this.recordType === 'CashOut') {
            return `CASH OUT# ${this.number} - ${this.name}`;
        }
        if (this.recordType === 'Invoice') {
            return `Invoice# ${this.number} - ${this.name}`;
        }
        
        if(this.recordType === 'Note') {
            return `${this.name} - ${this.email}`;
        }

        return this.name;
    }

    get description(): string {
        if (this.recordType === 'Entity') {
            return this.email;
        }
        if (this.recordType === 'MenuItem') {
            return this.email;
        }
        if (this.recordType === 'Task') {
            return this.additionalInfo;
        }
        if (this.recordType === 'PurchaseOrder') {
            return this.additionalInfo;
        }
        if (this.recordType === 'ContractorInvoice') {
            return this.additionalInfo;
        }
        if (this.recordType === 'CashOut') {
            return this.additionalInfo;
        }
        if (this.recordType === 'Invoice') {
            return this.additionalInfo;
        }
        if(this.recordType === 'Note') {
            return this.additionalInfo;
        }

        
        return this.address1 + ' ' + this.city + ', ' + this.state + ' ' + this.postalCode;
    }
}
