import { Component, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { Address, Entity, EntitySummary, Policy, Quote } from '@upkeeplabs/models/cogent';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { SalesApiService } from '@cogent/client/shared/services/api/sales-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { EntityEditModalComponent } from '../entity-edit/entity-edit-modal.component';
import { EntityFindModalComponent } from '../entity-find/entity-find-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { NewPolicy } from '@cogent/shared/models/sales/new-policy.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { ViewCoverageDialogComponent } from '../../parts/view-coverage/view-coverage.component';
import { PromotionCodeSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { CoverageType, DwellingType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';

export class QuoteDetails {
    address: Address;
    selectedPlan: PlanClient;
    holder: Entity;
    closingOfficer: Entity;
    buyersAgent: Entity;
    sellersAgent: Entity;
    escrowNumber: string;
    estimatedClosingDate: Date;
    totalAmountToSpend: number;
}

@Component({
    selector: 'app-new-quote',
    templateUrl: './new-quote.component.html', 
    styleUrls: ['./new-quote.component.css']
})
export class NewQuoteComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedIndex = 0;
    addressComplete = false;
    plans: PlanClient[];
    useMonthly = false;
    numbers = [0, 1, 2, 3, 4, 5, 6];
    comparePlansVisible = false;
    highlightedPlan: PlanClient;
    closingDetails = false;
    planIndex = 0;
    quote: QuoteDetails = new QuoteDetails();
    saving = false;
    fullQuote: Quote;
    loadingPlans = false;
    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    quoteId: string;
    promotionCodeSearch: string;
    promotionCode: PromotionCodeSummary;
    branchAgents: EntitySummary[];
    user: Entity;
    selectedAgent: Entity;

    constructor(private customerRepository: CustomerRepositoryService,
        private salesApi: SalesApiService,
        private missionService: MissionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sanitization: DomSanitizer,
        private matDialog: MatDialog,
        private dialogService: DialogsService,
        private policyApi: PolicyApiService,
        private entityApi: EntityApiService,
        private planApi: PlanApiService) {
        this.quote.address = new Address();
        this.quote.holder = new Entity();
        this.quote.holder.address = new Address();
        this.quote.holder.phoneNumbers = [];
    }

    ngOnInit() {
        this.selectedIndexChange(0);
        document.body.style.overflowY = 'scroll';

        this.entityApi.getLoggedInUser(true).then(user => {
            this.user = user;
            if (user.type === 'Customer' || user.type === 'Agent') {
                this.quote.buyersAgent = JSON.parse(JSON.stringify(user));
            } else if (user.type === 'ClosingOfficer') {
                this.quote.closingOfficer = JSON.parse(JSON.stringify(user));
            }
        });

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.salesApi.getQuote(params.id).then(quote => {
                    this.fullQuote = quote;
                    this.quote = quote.quoteDetailsObject;
                    this.quote.holder = new Entity();
                    this.quote.holder.address = new Address();
                    this.quote.holder.phoneNumbers = [];
                    this.selectedIndex = 4;
                    this.addressComplete = true;
                    this.loadPlans(false);
                    this.quoteId = params.id;
                });
            }
            if (params.amount && parseFloat(params.amount) > 0) {
                this.quote.totalAmountToSpend = parseFloat(params.amount);
            }
        });
    }

    get isTransactionCoordinator() {
        if (!this.user) {
            return false;
        }

        return this.user.type === 'TransactionCoordinator';
    }

    testEntityFind() {
        EntityFindModalComponent.FindEntity(this.matDialog, 'Agent', () => { });
    }

    ngOnDestroy(): void {
        UtilitiesService.swipeDetect(document, null);
    }

    getSampleContractUrl(item: PlanClient) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }

    showContractLanguage(option) {
        this.matDialog.open(ViewCoverageDialogComponent, { data: option.planItemId });
    }

    async findPromotionCode() {
        this.promotionCode = null;
        const code = await this.customerRepository.getPromotionCode(this.promotionCodeSearch, CoverageType.RealEstate);
        this.promotionCode = code;
        if (!code) {
            this.dialogService.alert('Not Found', 'Sorry, that promotion code could not be found');
        }
        this.promotionCodeSearch = '';
    }

    get adjustmentAmount() {
        if (!this.promotionCode || !this.quote.selectedPlan) {
            return 0;
        }

        if (this.promotionCode.priceAdjustmentType === 'Flat') {
            return this.promotionCode.priceAdjustment;
        } else if (this.promotionCode.priceAdjustmentType === "Percent") {
            return parseFloat((this.promotionCode.priceAdjustment * this.quote.selectedPlan.price).toFixed(2));
        } else if (this.promotionCode.priceAdjustmentType === "Months") {
            return 0;
        }
    }

    async loadPlans(navigate = true) {
        this.loadingPlans = true;
        const entity = await this.entityApi.getLoggedInUser(false);

        this.plans = await this.planApi.getOfferedPlans(CoverageType.RealEstate,
            DwellingType.SingleFamily,
            this.quote.address.postalCode,
            this.quote.address.squareFeet,
            this.quote.address.yearBuilt ? new Date().getFullYear() - this.quote.address.yearBuilt : 0,
            null,
            this.quote.address.latitude,
            this.quote.address.longitude,
            entity ? entity.id : null);
        this.loadingPlans = false;
        if (navigate) {
            this.selectedIndex = 1;
            if (this.quote.totalAmountToSpend && !this.quote.selectedPlan) {
                const selectedPlan = this.plans.filter(i => i.price <= this.quote.totalAmountToSpend)[0];
                if (selectedPlan) {
                    this.quote.selectedPlan = selectedPlan;
                    this.selectedIndex = 3;
                    return;
                }
            }
        }
    }

    getBackground(background: string) {
        return this.sanitization.bypassSecurityTrustStyle(background);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            UtilitiesService.swipeDetect(document, direction => {
                if (this.selectedIndex === 1 && direction === 'left' && this.planIndex < 2) {
                    this.planIndex++;
                }

                if (this.selectedIndex === 1 && direction === 'right' && this.planIndex > 0) {
                    this.planIndex--;
                }
            });
        }, 500);
    }

    addressCompleteChange(complete: boolean) {

        this.addressComplete = complete;
        if (complete) {
            this.salesApi.getPropertyMeta(this.quote.address.address1, this.quote.address.postalCode).then(meta => {
                if (meta) {
                    this.quote.address.squareFeet = meta.sqft;
                    this.quote.address.latitude = meta.lat;
                    this.quote.address.longitude = meta.lon;
                    this.quote.address.dwellingType = meta.useCode;
                    this.quote.address.yearBuilt = meta.yearBuilt;
                }
            });
        }
    }

    selectedPlanChange(selectedPlan: PlanClient) {
        this.selectedIndex = 2;
        this.quote.selectedPlan = selectedPlan;
    }

    get selectedOptions(): SalesItemModel[] {
        if (!this.quote || !this.quote.selectedPlan || !this.quote.selectedPlan.optionalItems) {
            return null;
        }

        return this.quote.selectedPlan.optionalItems.filter(i => i.selected);
    }

    get totalPrice(): number {
        if (!this.quote || !this.quote.selectedPlan) {
            return 0;
        }

        return this.planBasePrice + // this.quote.selectedPlan.price +
            (this.selectedOptions && this.selectedOptions.length > 0 ? this.selectedOptions.map(i => i.price).reduce((a, b) => a + b) : 0)
            + this.adjustmentAmount;
    }


    get planBasePrice(): number {
        if (!this.quote.selectedPlan) {
            return 0;
        }

        let amount = 0;

        amount = this.quote.selectedPlan.price;
        this.quote.selectedPlan.optionalItems.forEach(optionalItem => {
            if (optionalItem.isDefault) {
                amount -= optionalItem.price;
            }
        });

        return amount;
        // return this.newPolicy.policy.isMonthly ? (this.selectedPlan.priceRecurring) : this.selectedPlan.price;
    }


    get priceDifferential(): number {
        return this.totalPriceDisplay - this.totalPrice;
    }

    get totalPriceDisplay(): number {
        if (!this.quote.totalAmountToSpend || this.quote.totalAmountToSpend <= this.totalPrice) {
            return this.totalPrice;
        }

        return this.quote.totalAmountToSpend;
    }

    selectPlan(index) {
        if (index === 2) {
            this.lastPlanClicked();
        } else if (index === 1) {
            this.middlePlanClicked();
        } else if (index === 0) {
            this.firstPlanClicked();
        }
        this.highlightedPlan = this.plans[index];
    }

    private lastPlanClicked(showDetails = true) {
        this.comparePlansVisible = true;
        document.getElementById('plan-0').classList.add('collapse-left');
        document.getElementById('plan-selection-container1').classList.add('shift-left-1');
        setTimeout(() => {
            document.getElementById('plan-1').classList.add('collapse-left');
            document.getElementById('plan-selection-container1').classList.remove('shift-left-1');
            document.getElementById('plan-selection-container1').classList.add('shift-left-2');

            if (showDetails) {
                document.getElementById('plan-details').classList.remove('no-display');
            }
            setTimeout(() => {
                document.getElementById('plan-2').classList.add('expand-out');
                document.getElementById('plan-details').classList.add('visible');
                this.hidePlanSummaries();
            }, 500);

        }, 500);
    }

    private middlePlanClicked(showDetails = true) {
        this.comparePlansVisible = true;
        document.getElementById('plan-0').classList.add('collapse-left');
        document.getElementById('plan-2').classList.add('collapse-right');

        if (showDetails) {
            document.getElementById('plan-details').classList.remove('no-display');
        }
        setTimeout(() => {
            setTimeout(() => {
                document.getElementById('plan-1').classList.add('expand-out');
                document.getElementById('plan-details').classList.add('visible');
                this.hidePlanSummaries();
            }, 100);
        }, 500);
    }

    private firstPlanClicked(showDetails = true) {
        this.comparePlansVisible = true;
        if (document.getElementById('plan-2')) {
            document.getElementById('plan-2').classList.add('collapse-right');
        }
        document.getElementById('plan-selection-container1').classList.add('shift-right-1');
        setTimeout(() => {
            if (document.getElementById('plan-1')) {
                document.getElementById('plan-1').classList.add('collapse-right');
            }
            document.getElementById('plan-selection-container1').classList.remove('shift-right-1');
            document.getElementById('plan-selection-container1').classList.add('shift-right-2');

            if (showDetails) {
                document.getElementById('plan-details').classList.remove('no-display');
            }
            setTimeout(() => {
                if (document.getElementById('plan-0')) {
                    document.getElementById('plan-0').classList.add('expand-out');
                }
                document.getElementById('plan-details').classList.add('visible');
                this.hidePlanSummaries();
            }, 500);

        }, 500);
    }

    orderPlan(plan: PlanClient, index: number) {
        if (index === 0) {
            this.firstPlanClicked(false);
        } else if (index === 1) {
            this.middlePlanClicked(false);
        } else if (index === 2) {
            this.lastPlanClicked(false);
        }

        setTimeout(() => {
            this.quote.selectedPlan = plan;
            this.selectedIndex = 2;
        }, index === 1 ? 1000 : 1500);
    }

    private hidePlanSummaries() {
        setTimeout(() => {

            document.getElementById('plan-selection-container1').classList.add('no-display');
        }, 500);
    }



    private hidePlanDetails() {
        setTimeout(() => {

            document.getElementById('plan-details').classList.add('no-display');
        }, 500);
    }

    showCompareClicked() {
        this.comparePlansVisible = true;
        this.highlightedPlan = null;
        document.getElementById('plan-0').classList.add('collapse-left');
        document.getElementById('plan-2').classList.add('collapse-right');
        document.getElementById('plan-1').classList.add('collapse');

        setTimeout(() => this.hidePlanSummaries(), 500);
        document.getElementById('plan-details').classList.remove('no-display');
        setTimeout(() => document.getElementById('plan-details').classList.add('visible'));
    }

    usePropertyAddress() {
        this.quote.holder.address.address1 = this.quote.address.address1;
        this.quote.holder.address.city = this.quote.address.city;
        this.quote.holder.address.state = this.quote.address.state;
        this.quote.holder.address.postalCode = this.quote.address.postalCode;
    }

    findBuyersAgent() {
        EntityFindModalComponent.FindEntity(this.matDialog, 'Agent', entity => {
            this.quote.buyersAgent = entity;
        });
    }

    findSellersAgent() {
        EntityFindModalComponent.FindEntity(this.matDialog, 'Agent', entity => {
            this.quote.sellersAgent = entity;
        });
    }

    clearSellersAgent() {
        this.quote.sellersAgent = null;
    }

    clearBuyersAgent() {
        this.quote.buyersAgent = null;
    }

    editBuyersAgent() {
        EntityEditModalComponent.EditEntity(this.matDialog, this.quote.buyersAgent, entity => {
            this.quote.buyersAgent = entity;
        });
    }

    editSellersAgent() {
        EntityEditModalComponent.EditEntity(this.matDialog, this.quote.sellersAgent, entity => {
            this.quote.sellersAgent = entity;
        });
    }

    findClosingOfficer() {
        EntityFindModalComponent.FindEntity(
            this.matDialog, 'ClosingOfficer', entity => {
                this.quote.closingOfficer = entity;
            });
    }

    editClosingOfficer() {
        EntityEditModalComponent.EditEntity(
            this.matDialog, this.quote.closingOfficer,
            entity => { this.quote.closingOfficer = entity; });
    }

    clearClosingOfficer() {
        this.quote.closingOfficer = null;
    }

    get customerContactInformationValid() {
        return this.quote.holder.name
            && this.quote.holder.address.address1
            && this.quote.holder.address.city
            && this.quote.holder.address.state
            && this.quote.holder.address.postalCode
            && this.quote.holder.email
            && UtilitiesService.validateEmail(this.quote.holder.email)
            && (UtilitiesService.validatePhoneNumber(this.quote.holder.homeNumber.number) || UtilitiesService.validatePhoneNumber(this.quote.holder.mobileNumber.number));
    }

    closeButtonClicked() {
        this.closingDetails = true;
        this.comparePlansVisible = false;

        if (!document.getElementById('plan-selection-container1')) {

            setTimeout(() => this.closeButtonClicked(), 500);
            return;
        }
        document.getElementById('plan-selection-container1').classList.remove('no-display');
        setTimeout(() => {

            if (document.getElementById('plan-0')) {
                document.getElementById('plan-0').classList.remove('expand-out');
            }
            if (document.getElementById('plan-1')) {
                document.getElementById('plan-1').classList.remove('expand-out');
            }
            if (document.getElementById('plan-2')) {
                document.getElementById('plan-2').classList.remove('expand-out');
            }
            if (document.getElementById('plan-details')) {
                document.getElementById('plan-details').classList.remove('visible');
            }


            setTimeout(() => {
                document.getElementById('plan-selection-container1').classList.remove('shift-right-1');
                document.getElementById('plan-selection-container1').classList.remove('shift-right-2');
                document.getElementById('plan-selection-container1').classList.remove('shift-left-1');
                document.getElementById('plan-selection-container1').classList.remove('shift-left-2');

                if (document.getElementById('plan-0')) {
                    document.getElementById('plan-0').classList.remove('collapse-right');
                    document.getElementById('plan-0').classList.remove('collapse-left');
                }
                if (document.getElementById('plan-1')) {
                    document.getElementById('plan-1').classList.remove('collapse-right');
                    document.getElementById('plan-1').classList.remove('collapse-left');
                    document.getElementById('plan-1').classList.remove('collapse');
                }

                if (document.getElementById('plan-2')) {
                    document.getElementById('plan-2').classList.remove('collapse-right');
                    document.getElementById('plan-2').classList.remove('collapse-left');
                }
                this.hidePlanDetails();

            }, 500);

        }, 100);

        setTimeout(() => this.closingDetails = false, 700);
    }



    optionSelected(option: SalesItemModel) {
        if (option.selected && option.quantity === 0) {
            option.quantity = 1;
        }
        if (!option.selected) {
            option.quantity = 0;
        }
    }

    async selectedIndexChange(index) {
        document.location.hash = index;

        setTimeout(() => {
            this.closeButtonClicked();
        }, 500);
        if (index === 5 && !this.branchAgents) {
            const user = await this.entityApi.getLoggedInUser(false);
            if (user.type === 'TransactionCoordinator') {
                this.branchAgents = await this.salesApi.getBranchAgents();
            }
        }
        if (index === 4 && !this.quote.holder.address.address1) {
            this.usePropertyAddress();
        }
    }

    agentUpdated(agentId: string) {
        this.entityApi.getFullEntity(agentId).then(agent => {
            this.quote.buyersAgent = agent;
        });
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }


        let hashNumber = parseInt(hash, 10);

        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }

    }

    async saveQuote() {
        this.saving = true;
        const quote = new Quote();
        quote.id = UtilitiesService.newid();
        quote.planName = this.quote.selectedPlan.name;
        quote.total = this.totalPrice;
        quote.quoteDetails = JSON.stringify(this.quote);
        quote.propertyAddress1 = this.quote.address.address1;

        await this.salesApi.saveQuote(quote);

        this.saving = false;
        this.missionService.showSuccessToast('Quote Saved');
        this.router.navigateByUrl(`view-quote/${quote.id}`);
    }

    get propertyStreetView(): string {
        if (!this.quote.address || !this.quote.address.address1) {
            return null;
        }

        const url = 'https://maps.googleapis.com/maps/api/streetview?size=400x400&location='
            + this.quote.address.address1 +
            ' ' + this.quote.address.city + ' ' + this.quote.address.state
            + ' ' + this.quote.address.postalCode + '&key=AIzaSyB1xEizJ3bh0fvxTQkB-Pit7cPkzgr-tl0';

        return encodeURI(url);
    }

    async createPolicy() {
        this.saving = true;
        const policy = new Policy();
        const newPolicy = new NewPolicy();
        if (!this.quote.address.id) {
            this.quote.address.id = UtilitiesService.newid();
        }

        if (!this.quote.holder.id) {
            this.quote.holder.id = UtilitiesService.newid();
        }
        if (!this.quote.holder.address.id) {
            this.quote.holder.address.id = UtilitiesService.newid();
        }
        for (const phone of this.quote.holder.phoneNumbers) {
            if (!phone.id) {
                phone.id = UtilitiesService.newid();
                phone.entityId = this.quote.holder.id;
            }
        }

        newPolicy.policy = policy;
        newPolicy.policy.policyNumber = await this.policyApi.getNextPolicyNumber();
        newPolicy.coverageType = 'RealEstate';
        newPolicy.holder = this.quote.holder;
        newPolicy.holderAddress = this.quote.holder.address;
        newPolicy.holderPhoneNumbers = this.quote.holder.phoneNumbers;
        newPolicy.options = this.selectedOptions;
        newPolicy.property = this.quote.address;
        if (this.user && this.user.type === 'TransactionCoordinator') {
            newPolicy.policy.transactionCoordinatorId = this.user.id;
        }

        if (this.quote.totalAmountToSpend && this.quote.totalAmountToSpend >= this.totalPrice) {
            newPolicy.policy.titleInvoiceAmount = this.quote.totalAmountToSpend;
        }

        if (this.promotionCode) {
            newPolicy.promotionCode = this.promotionCode.code;
        }

        policy.id = UtilitiesService.newid();

        const accountExec = await this.salesApi.getMyAE();
        const loggedInUser = await this.entityApi.getLoggedInUser();
        if (accountExec) {
            policy.accountExecutiveId = accountExec.assignedAccountExecutiveId;
        }
        policy.addressId = this.quote.address.id;
        if (this.user.type === 'Agent') {
            policy.agentId = loggedInUser.id;
        } else {
            if (this.quote.buyersAgent) {
                policy.agentId = this.quote.buyersAgent.id;
            }
        }

        policy.initiatorType = loggedInUser.type;
        policy.monthlyProcessingFee = this.quote.selectedPlan.monthlyProcessingFee;
        policy.planId = this.quote.selectedPlan.id;
        policy.basePrice = this.quote.selectedPlan.price;
        policy.basePriceRecurring = this.quote.selectedPlan.priceRecurring;
        if (this.quote.closingOfficer) {
            policy.closingOfficerId = this.quote.closingOfficer.id;
        }
        policy.coverageType = 'RealEstate';
        policy.escrowNumber = this.quote.escrowNumber;
        policy.estimatedClosingDate = this.quote.estimatedClosingDate;
        policy.holderId = this.quote.holder.id;
        policy.initiatorType = 'Unknown';
        if (this.quote.sellersAgent) {
            policy.sellerAgentId = this.quote.sellersAgent.id;
        }
        policy.serviceFeeAmount = this.quote.selectedPlan.serviceFee;
        policy.status = 'Pending';

        await this.policyApi.saveNewPolicy(newPolicy);
        if (this.quoteId) {
            await this.salesApi.markQuoteComplete(this.quoteId, policy.id);
        }
        this.missionService.showSuccessToast('Subscription Created');
        this.saving = false;
        this.router.navigateByUrl('/home');
    }

}


