import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class NoteSummary {
    @keyColumn() id: string;
    @column() noteText: string;
    @column() entityId: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() taskId: string;
    @column() pinned: boolean;
    @column() creatorName: string;
    @column() workOrderNumber: bigint;
    @column() type: string;
    @column() purchaseOrderId: string;
    @column() showOnContractorPortal: boolean;
    @column() isPrivate: boolean;
    @column() metaData: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() creatorType: string;
    @column() employeeName: string;
    @column() employeeEmail: string;
    @column() contractorName: string;



    // Client side only

    public attachments: any[];

    private _meta: any;

    get meta() {
        if (!this._meta && this.metaData) {
            try {
                this._meta = JSON.parse(this.metaData);
            } catch (e) { }
        }

        return this._meta;
    }
}