import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Note {
    @keyColumn() id: string;
    @column() noteText: string;
    @column() entityId: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() createdById?: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() taskId: string;
    @column() pinned: boolean;
    @column() type: string;
    @column() purchaseOrderId: string;
    @column() showOnContractorPortal: boolean;
    // @column() version: timestamp;
    @column() workOrderLineId: string;
    @column() isPrivate: boolean;
    @column() metaData: string;
    @column() removed: boolean;
    @column() parentNoteId: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() dispatchExportDate?: Date;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() workorderLineAuthorizationId: string;

    creatorName: string;
}